//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  name: "newuser",
  data() {
    return {
      separator: "vertical",
      columns: [
        {
          name: "model",
          label: "Producto",
          field: "model",
          align: "center",
          sortable: true
        },
        {
          name: "quantity",
          label: "Cantidad",
          field: "quantity",
          align: "center",
          sortable: true
        }
      ]
    };
  },
  created() {
    this.stockvoucher = this.$route.params.stockvoucher;
  },
  methods: {
    converttimestamp: function(date){
      return moment(date.seconds *1000).format("DD/MM/YYYY") 
    },
    gotostocklist: function() {
      this.$router.push({ name: "stocklist" });
    }
  }
};
