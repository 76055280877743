import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase';
import store from './store';
import moment from "moment";
import axios from 'axios';
import './quasar';
import './registerServiceWorker';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use({
  defaultIconPack: 'fas',
  defaultDateFormatter: date => {
    return moment(date).format("DD/MM/YYYY");
  },
  defaultDateParser: date => {
    return moment(date, "DD/MM/YYYY").toDate();
  }
});
Vue.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAM9rr4WxJnk1GD1Hcyce93lM7jWq_guX0',
    libraries: ['...']
  }
})
const config = {
  apiKey: "AIzaSyAM9rr4WxJnk1GD1Hcyce93lM7jWq_guX0",
  authDomain: "ditgroup-3dc1a.firebaseapp.com",
  databaseURL: "https://ditgroup-3dc1a.firebaseio.com",
  projectId: "ditgroup-3dc1a",
  storageBucket: "ditgroup-3dc1a.appspot.com",
  messagingSenderId: "588567016980",
  appId: "1:588567016980:web:51d74c1094429508355901",
  measurementId: "G-E40ZZRK013"
}
/*var test_config = {
  apiKey: "AIzaSyBzxKqcXQzvaJpmwRXkWHPsWerCDNUruMs",
  authDomain: "ditgroup-test.firebaseapp.com",
  databaseURL: "https://ditgroup-test.firebaseio.com",
  projectId: "ditgroup-test",
  storageBucket: "ditgroup-test.appspot.com",
  messagingSenderId: "151339312571",
  appId: "1:151339312571:web:5ae907e752ae62faa7d09e"
};*/
firebase.initializeApp(config);
//Messaging
//const messaging = firebase.messaging();
//messaging.usePublicVapidKey("BHoY-Tv0V9EfIwcSN40rTkskyWP-sMHM6hLIDsGrJaG_bu-57MsPzJq6XfAox6ZYx6m3Yc9ZzE5PnbIu7Ses4WM");
/*messaging.requestPermission().then(() => {
  console.log('Permiso de notificación otorgado');
  // Obtener token
  messaging.getToken().then((token) => {
    console.log(token)
  })
}).catch((err) => {
  console.log('No se puede obtener permiso para notificar.', err);
});
messaging.getToken().then((currentToken) => {
  if (currentToken) {
    //sendTokenToServer(currentToken);
    //updateUIForPushEnabled(currentToken);
    console.log(currentToken);
  } else {
    // Show permission request.
    console.log('No Instance ID token available. Request permission to generate one.');
    // Show permission UI.
    //updateUIForPushPermissionRequired();
    //setTokenSentToServer(false);
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  //showToken('Error retrieving Instance ID token. ', err);
  //setTokenSentToServer(false);
});
messaging.onTokenRefresh(() => {
  messaging.getToken().then((refreshedToken) => {
    console.log('Token refreshed.');
    // Indicate that the new Instance ID token has not yet been sent to the
    // app server.
    //setTokenSentToServer(false);
    // Send Instance ID token to app server.
    //sendTokenToServer(refreshedToken);
    // ...
    console.log(refreshedToken);
  }).catch((err) => {
    console.log('Unable to retrieve refreshed token ', err);
    //showToken('Unable to retrieve refreshed token ', err);
  });
});
*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
