//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import lisofproducts from "../../../../components/projects/sites/listofproducts";
import moment from "moment";
export default {
  data() {
    return {
      site: [],
      siteworks: [],
      contractors: [],
      project: {},
      finishSiteAlert: false,
      finishSiteConfirm: false,
      finishflag: false
    };
  },
  created() {
    this.site = this.$route.params.site;
  },
  beforeMount() {
    this.getContractors();
    this.getProject();
    this.getSiteWorks();
  },
  components: {
    lisofproducts
  },
  methods: {
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getProject: function() {
      const projectid = this.site.projectid;
      const self = this;
      firebase
        .firestore()
        .collection("projects")
        .doc(projectid)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            //
            self.project = doc.data();
            self.project.id = doc.id;
            firebase
              .firestore()
              .collection("users")
              .doc(self.project.projectmanagerid)
              .get()
              .then(function(doc) {
                let projectmanager = doc.data();
                projectmanager.id = doc.id;
                self.project.projectmanager = projectmanager;
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    getSiteWorks: function() {
      const self = this;
      firebase
        .firestore()
        .collection("sitework")
        .where("siteid", "==", self.site.id)
        .onSnapshot(querySnapshot => {
          let works = [];
          querySnapshot.forEach(doc => {
            let work = doc.data();
            work.id = doc.id;
            self.contractors.forEach(x => {
              if (x.id == work.contractorid) {
                work.contractorname = x.name;
              }
            });
            works.push(work);
          });
          self.siteworks = works;
        });
    },
    getContractors: function() {
      const self = this;
      firebase
        .firestore()
        .collection("contractors")
        .onSnapshot(querySnapshot => {
          let contractors = [];
          querySnapshot.forEach(doc => {
            let contractor = doc.data();
            contractor.id = doc.id;
            contractors.push(contractor);
          });
          self.contractors = contractors;
        });
    },
    gotoProject: function() {
      this.$router.push({
        name: "project",
        params: { project: this.project }
      });
    },
    gotowork: function(work) {
      this.$router.push({
        name: "sitework",
        params: { work: work, site: this.site }
      });
    },
    addcontractor: function() {
      this.$router.push({
        name: "addcontractortosite",
        params: { site: this.site }
      });
    },
    gotoFinishSite: function() {
      const self = this;
      firebase
        .firestore()
        .collection("sitematerials")
        .where("siteid", "==", self.site.id)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            let request = doc.data();
            if (request.productquantity != request.used) {
              self.finishflag = true;
            }
          });
          if (self.finishflag) {
            self.finishSiteAlert = true;
          } else {
            self.$q
              .dialog({
                title: "Confirmar",
                message: "Confirme que desea Terminar el trabajo",
                cancel: {
                  push: true,
                  color: "negative",
                  label:"Cancelar"
                },
                ok: {
                  push: true,
                  color: "primary",
                  label:"Confirmar"
                },
                persistent: true
              })
              .onOk(() => {
                // console.log('>>>> OK')
              })
              .onOk(() => {
                // console.log('>>>> second OK catcher')
              })
              .onCancel(() => {
                // console.log('>>>> Cancel')
              })
              .onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              });
          }
        });
    }
  }
};
