//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import firebase from "firebase";
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      isPwd: true,
      errorMessage: "",
      dense: true,
      submitting: false,
    };
  },
  computed: {
    ...mapState(["profile", "islogged"]),
  },
  methods: {
    ...mapMutations(["auth_mutation"]),
    login: function () {
      this.submitting = true;
      const self = this;
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase
            .auth()
            .signInWithEmailAndPassword(self.email, self.password)
            .then((data) => {
              var docRef = firebase
                .firestore()
                .collection("users")
                .doc(data.user.uid);
              docRef.get().then(function (document) {
                const data = document.data();
                var profile = {
                  id: document.id,
                  name: data.name,
                  email: data.email,
                  role: data.role,
                  ci: data.ci,
                  status: data.status,
                  profileImagePath: data.profileImagePath,
                  islogged: true,
                };
                if (data.status) {
                  self.$store.commit("auth_mutation", profile);
                  switch (data.role) {
                    case "Jefe de Compras y Ventas":
                      self.$router.push({ name: "purchasesandsales" });
                      break;
                    case "Agente de Ventas":
                      self.$router.push({ name: "purchasesandsales" });
                      break;
                    case "Project Manager":
                      self.$router.push({ name: "projects" });
                      break;
                    case "Jefe de Nacional de Almacenes":
                      self.$router.push({ name: "warehouses" });
                      break;
                    case "Jefe de Almacen":
                      self.$router.push({ name: "warehouses" });
                      break;
                    case "Supervisor":
                      self.$router.push({ name: "projects" });
                      break;
                    case "Gerente General":
                      self.$router.push({ name: "userslist" });
                      break;
                    case "Responsable de División Outsourcing y RRHH":
                      self.$router.push({ name: "RRHH" });
                      break;
                    case "Jefe de Marketing":
                      self.$router.push({ name: "Marketing" });
                      break;
                    case "Especialista de adquisiciones":
                      self.$router.push({ name: "Acquisitions" });
                      break;
                    default:
                      self.$router.push({ name: "home" });
                      break;
                  }
                } else {
                  self.logout;
                  self.$q.notify({
                    message: "Usuario no habilitado!",
                    color: "warning",
                    position: "top",
                  });
                  self.email = "";
                  self.password = "";
                  self.submitting = false;
                }
              });
            })
            .catch(function (error) {
              self.$q.notify({
                message: "Error",
                caption: error.message,
                type: "negative",
              });
              self.submitting = false;
            });
        })
        .catch(function (error) {
          // Handle Errors here.
          self.$q.notify({
            message: "Error",
            caption: error.message,
            type: "negative",
          });
          self.submitting = false;
        });
    },
    logout: function () {
      const self = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          self.$router.push({
            name: "login",
          });
        });
    },
    resetpassword: function () {
      const self = this;
      if (this.email != "") {
        var auth = firebase.auth();
        auth
          .sendPasswordResetEmail(this.email)
          .then(function () {
            self.$q.notify({
              message: "Enviado",
              caption: "Se envio un correo a" + self.email,
              type: "positive",
            });
          })
          .catch(function (error) {
            self.$q.notify({
              message: "Error",
              caption: "Correo Electronico no registrado",
              type: "negative",
            });
            console.log(error);
          });
      } else {
        this.$q.notify({
          message: "Error",
          caption: "Introduzca un correo electronico valido",
          type: "negative",
        });
      }
    },
  },
};
