//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  data() {
    return {
      productsList: [],
      vouchercode: "",
      description: "",
      proofofsale: null,
      status: "",
      statusoptions: ["En Almacen", "En Camino"],
      date: Date.now(),
      warehousesList: [],
      warehouse: "",
      listofproductsinvoucher: [],
      lofivcolumns: [
        {
          name: "model",
          align: "left",
          label: "Model",
          field: "model",
          sortable: true,
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true,
        },
      ],
      productquantity: 0,
      productid: null,
      submitting: false,
      dense: true,
    };
  },
  beforeMount() {
    this.getwarehouseslist();
    this.getproductslist();
  },
  methods: {
    getwarehouseslist: function () {
      const self = this;
      this.isWarehousesEmpty = false;
      firebase
        .firestore()
        .collection("warehouses")
        .where("enable", "==", true)
        .onSnapshot((querySnapshot) => {
          let warehouses = [];
          querySnapshot.forEach((doc) => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouses.push(warehouse);
          });
          self.warehousesList = warehouses;
        });
    },
    getproductslist: function () {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("products")
        .onSnapshot((querySnapshot) => {
          let products = [];
          querySnapshot.forEach((doc) => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          self.productsList = products;
        });
    },
    addproducttovoucher: function () {
      if (this.productid != null && this.productquantity > 0) {
        this.isproductsListEmpty = false;
        var prodname;
        const self = this;
        this.productsList.forEach(function (item) {
          if (self.productid == item.id) {
            prodname = item.model;
          }
        });
        this.listofproductsinvoucher.push({
          id: this.productid,
          model: prodname,
          quantity: Number(this.productquantity),
        });
        this.productid = null;
        this.productquantity = 0;
      } else {
        self.$q.notify("Ingrese los datos correctos. ");
      }
    },
    createvoucher: function () {
      if (
        this.listofproductsinvoucher.length == 0 ||
        this.proofofsale == null
      ) {
        this.$q.notify({
          message: '<span style="color: black">Introduzca todos los campos requeridos.use</span>',
          color: "warning",
          html: true
        });
        this.submitting = false;
      } else {
        const self = this;
        this.submitting = true;
        const _date=moment(String(self.date)).format('MM/DD/YYYY');
        firebase
          .firestore()
          .collection("stockvoucher")
          .add({
            vouchercode: Math.floor(Math.random() * 100000) + 1,
            description: self.description,
            status: "En Camino",
            warehouse: self.warehouse,
            date: new Date(_date),
            listofproductsinvoucher: self.listofproductsinvoucher,
          })
          .then((doc) => {
            self.uploadfile(self.proofofsale, doc.id);
            if (self.status == "En Almacen") {
              self.addstock(
                self.listofproductsinvoucher,
                doc.id,
                self.warehouse
              );
            }
            self.onReset();
            self.$q.notify({
              type: "positive",
              message: `Comprobante agregado correctamente.`,
            });
          })
          .catch(function (error) {
            self.$q.notify("Error writing document: ", error);
          });
      }
    },
    uploadfile: function (file, productid) {
      //const self = this;
      const pid = productid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Create the file metadata
      /*var metadata = {
        contentType: "image/jpeg"
      };*/

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("stockvoucher/" + pid + ".pdf")
        .put(file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("stockvoucher").doc(pid).update({
              proofofsaleImagePath: downloadURL,
            });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    addstock: function (products, stockid, warehouse) {
      products.forEach((product) => {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", warehouse)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              firebase
                .firestore()
                .collection("stock")
                .add({
                  productid: product.id,
                  productmodel: product.model,
                  productquantity: Number(product.quantity),
                  warehouse: warehouse,
                });
            } else {
              querySnapshot.forEach(function (doc) {
                let data = doc.data();
                let newquantity =
                  Number(product.quantity) + Number(data.productquantity);
                firebase
                  .firestore()
                  .collection("stock")
                  .doc(doc.id)
                  .update({
                    productquantity: Number(newquantity),
                  });
              });
            }
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then((doc) => {
                let data = doc.data();
                let newstock = Number(data.stock) + Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock,
                  });
              });
          });
      });
    },
    productlist: function () {
      this.$router.push({ name: "productslist" });
    },
    onReset() {
      this.vouchercode = "";
      this.description = "";
      this.proofofsale = null;
      this.status = "";
      this.date = Date.now();
      this.warehouse = "";
      this.listofproductsinvoucher = [];
      this.productquantity = 0;
      this.productid = null;
      this.submitting = false;
    },
  },
};
