//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  props: ["projectid"],
  data() {
    return {
      filter: "",
      data: [],
      columns: [
        {
          name: "productmodel",
          label: "Producto",
          field: "productmodel",
          sortable: true,
          align: "left"
        },
        {
          name: "productquantity",
          label: "Cantidad",
          field: "productquantity",
          sortable: true,
          align: "left"
        },
        {
          name: "used",
          label: "Usado",
          field: "used",
          sortable: true,
          align: "left"
        }
      ]
    };
  },
  beforeMount() {
    this.getproducts();
  },
  methods: {
    getproducts: function() {
      const self = this;
      firebase
        .firestore()
        .collection("sitematerials")
        .where("projectid", "==", this.projectid)
        .onSnapshot(querySnapshot => {
          let requests = [];
          querySnapshot.forEach(doc => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    }
  }
};
