//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import returnproduct from "../../components/warehouse/returns/aprobeproductreturn";
export default {
  data() {
    return {
      name: "",
      city: "",
      filter: "",
      warehouses: [],
      toupdate: false,
      warehouseid: null,
      warehousemanagerList: [],
      warehousemanager: "",
      submitting: false,
      columns: [
        {
          name: "name",
          align: "left",
          label: "Nombre",
          field: "name",
          sortable: true,
        },
        {
          name: "city",
          align: "left",
          label: "Ciudad",
          field: "city",
          sortable: true,
        },
        {
          name: "warehousemanager",
          align: "left",
          label: "Jefe de Almacén",
          field: "warehousemanager",
          sortable: true,
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
      pagination: {
        sortBy: "desc",
        descending: false,
        rowsPerPage: 25,
        rowsNumber: this.userlistlenght,
      },
    };
  },
  components: {
    returnproduct,
  },
  beforeMount() {
    this.getproductslist();
    this.getwarehousemanagerlist();
  },
  methods: {
    //registerwarehouse: function(params) {},
    getproductslist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("warehouses")
        .onSnapshot((querySnapshot) => {
          let warehouseslist = [];
          querySnapshot.forEach((doc) => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouseslist.push(warehouse);
          });
          self.warehouses = warehouseslist;
        });
    },
    getwarehousemanagerlist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Jefe de Almacén")
        .onSnapshot((querySnapshot) => {
          let warehousemanagerList = [];
          querySnapshot.forEach((doc) => {
            let warehousemanager = doc.data();
            warehousemanager.id = doc.id;
            warehousemanagerList.push(warehousemanager);
          });
          self.warehousemanagerList = warehousemanagerList;
        });
    },
    registerwarehouse: function () {
      const self = this;
      firebase
        .firestore()
        .collection("warehouses")
        .add({
          name: self.name,
          city: self.city,
          warehousemanagerid: self.warehousemanager,
          enable: true,
        })
        .catch(function (error) {
          self.$buefy.toast.open("Error writing document: ", error);
        });
    },
    editwarehouse: function (warehouse) {
      this.toupdate = true;
      this.name = warehouse.name;
      this.city = warehouse.city;
      this.warehouseid = warehouse.id;
      this.warehousemanager = warehouse.warehousemanagerid;
    },

    updatewarehouse: function () {
      const self = this;
      firebase
        .firestore()
        .collection("warehouses")
        .doc(this.warehouseid)
        .update({
          name: self.name,
          city: self.city,
          warehousemanagerid: self.warehousemanager,
        });
      this.onReset();
    },
    viewwarehouse: function (warehouse) {
      this.$router.push({
        name: "viewwarehouse",
        params: { warehouse: warehouse },
      });
    },
    enablewarehouse: function (warehouseid) {
      firebase.firestore().collection("warehouses").doc(warehouseid).update({
        enable: true,
      });
    },
    disablewarehouse: function (warehouseid) {
      firebase.firestore().collection("warehouses").doc(warehouseid).update({
        enable: false,
      });
    },
    onReset() {
      this.name = "";
      this.city = "";
      this.toupdate = false;
      this.warehouseid = null;
    },
    gotoproductlist: function () {
      this.$router.push({ name: "productslist" });
    },
  },
};
