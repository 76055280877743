//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      site: {},
      contractorslist: [],
      sitematerials: [],
      newsitework: {},
      notephoto: null,
      materialphoto: null,
      geoposition: new firebase.firestore.GeoPoint(51.435454, 31.445456),
    };
  },
  created() {
    this.site = this.$route.params.site;
  },
  beforeMount() {
    this.getcontractors();
    this.getsitematerials();
  },
  methods: {
    getcontractors: function () {
      const self = this;
      firebase
        .firestore()
        .collection("contractors")
        .onSnapshot((querySnapshot) => {
          let contractors = [];
          querySnapshot.forEach((doc) => {
            let contractor = doc.data();
            contractor.id = doc.id;
            contractors.push(contractor);
          });
          self.contractorslist = contractors;
        });
    },
    getsitematerials: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sitematerials")
        .where("siteid", "==", self.site.id)
        .onSnapshot((querySnapshot) => {
          let sitematerials = [];
          querySnapshot.forEach((doc) => {
            let material = doc.data();
            material.id = doc.id;
            material.maxquantity = material.productquantity - material.used;
            material.value = 0;
            sitematerials.push(material);
          });
          self.sitematerials = sitematerials;
        });
    },
    createSiteWork: function () {
      const self = this;
      let materials = [];
      this.sitematerials.forEach((x) => {
        if (x.value > 0) {
          let material = {};
          material.id = x.productid;
          material.quantity = x.value;
          material.model = x.productmodel;
          material.used = 0;
          materials.push(material);
        }
      });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            self.geoposition = new firebase.firestore.GeoPoint(
              position.coords.latitude,
              position.coords.longitude
            );
            firebase
              .firestore()
              .collection("sitework")
              .add({
                name: self.newsitework.name,
                contractorid: self.newsitework.contractorid,
                materials: materials,
                siteid: self.site.id,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                status: true,
                location: self.geoposition,
              })
              .then((work) => {
                const id = work.id;
                self.uploadnote(self.notephoto, id);
                self.uploadmaterial(self.materialphoto, id);
                self.sitematerials.forEach((x) => {
                  if (x.value >= 0) {
                    let used = x.used + x.value;
                    firebase
                      .firestore()
                      .collection("sitematerials")
                      .doc(x.id)
                      .update({
                        used: used,
                      })
                      .then(() => {
                        self.openSite();
                      });
                  }
                });
              });
          },
          (error) => {
            console.log(error.message);
          }
        );
      }
    },

    openSite: function () {
      this.$router.push({
        name: "viewprojectsite",
        params: { site: this.site },
      });
    },
    uploadnote: function (file, docid) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("site/work/notes/" + id + ".jpg")
        .put(file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("sitework").doc(id).update({
              noteurl: downloadURL,
            });
          });
        }
      );
    },
    uploadmaterial: function (file, docid) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("site/work/materials/" + id + ".jpg")
        .put(file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("sitework").doc(id).update({
              materialurl: downloadURL,
            });
          });
        }
      );
    },
  },
};
