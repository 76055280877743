//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      columns: [
        {
          name: "code",
          align: "center",
          label: "Código",
          field: "code",
          sortable: true
        },
        {
          name: "name",
          align: "center",
          label: "Proyecto",
          field: "name",
          sortable: true
        },
        {
          name: "client",
          align: "center",
          label: "Cliente",
          field: "client",
          sortable: true
        },
        {
          name: "projectmanager",
          align: "center",
          label: "Project Manager",
          field: "projectmanager",
          sortable: true
        },
        {
          name: "actions",
          align: "center",
          label: "Acciones",
          field: "actions",
          sortable: true
        }
      ],
      data: [],
      filter:'',
      projectmanagerlist:[]
    };
  },

  beforeMount() {
    this.getprojectmanagerlist();
    this.getprojects();
  },
  methods: {
    getprojectmanagerlist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Project Manager")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.projectmanagerlist = agents;
        });
    },
    getprojects: function() {
      const self = this;
      firebase
        .firestore()
        .collection("projects")
        .onSnapshot(querySnapshot => {
          let projects = [];
          querySnapshot.forEach(doc => {
            let project = doc.data();
            project.id = doc.id;
            self.projectmanagerlist.forEach(x => {
              if (x.id == project.projectmanagerid) {
                project.projectmanager = x;
              }
            });
            projects.push(project);
          });
          self.data = projects;
        });
    },
    editProject:function (params) {
      this.$router.push({ name: "editProject", params: { project: params } });
    },
    viewProject:function (params) {
      this.$router.push({ name: "project", params: { project: params } });
    }
  }
};
