//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  name: "newproject",
  data() {
    return {
      submitting: false,
      project: {},
      site: {},
      supervisorlist: [],
      datemin:"",
      datemax:""
    };
  },

  created() {
    this.project = this.$route.params.project;
  },
  beforeMount() {
    this.getsupervisorlist();
    this.optionsFn();
  },
  methods: {
    getsupervisorlist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Supervisor")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.supervisorlist = agents;
        });
    },
    addprojectsite: function() {
      const self = this;
      this.submitting = true;
      const _dateinit=moment(String(self.site.date_init)).format('MM/DD/YYYY');
      const _dateend=moment(String(self.site.date_end)).format('MM/DD/YYYY');
      firebase
        .firestore()
        .collection("projectsites")
        .add({
          name: self.site.name,
          supervisorid: self.site.supervisorid,
          purchase_order: self.site.purchase_order,
          date_init: new Date(_dateinit),
          date_end: new Date(_dateend),
          projectid: self.project.id,
          status:"En Proceso"
        });
      self.onReset();
      self.gotoproject();
    },
    onReset: function() {
      this.site = {};
      this.submitting = false;
    },
    gotoproject: function() {
      this.$router.push({ name: "project", params: { project: this.project } });
    },
    optionsFn: function() {
      this.datemin= this.converttimestamp(this.project.dateinit);
      this.datemax= this.converttimestamp(this.project.dateend);
    },
    converttimestamp: function(date) {
      return moment(date.seconds * 1000).format("YYYY-MM-DD");
    }
  }
};
