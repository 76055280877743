//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "login",
  data() {
    return {
      productslist: [],
      stockincominglist: [],
      userlistlenght: 0,
      loading: true,
      filter: "",
      pagination: {
        sortBy: "desc",
        descending: false,
        rowsPerPage: 25,
        rowsNumber: this.userlistlenght
      },
      columns: [
        {
          name: "productImagePath",
          align: "left",
          label: "Imagen",
          field: "productImagePath",
          sortable: true
        },
        {
          name: "model",
          required: true,
          label: "Nombre",
          align: "left",
          field: row => row.model,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "stock",
          align: "left",
          label: "Stock",
          field: "stock",
          sortable: true
        },
        {
          name: "stockincoming",
          align: "left",
          label: "Stock en camino(Fecha)",
          field: "stockincoming",
          sortable: true
        },
        { name: "actions", label: "Acciones", field: "", align: "center" }
      ]
    };
  },
  beforeMount() {
    this.getstockincoming();
    this.getproductslist();
  },
  computed: {
    ...mapState(["profile", "islogged"])
  },
  methods: {
    converttimestamp: function(date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getproductslist: function() {
      const self = this;

      firebase
        .firestore()
        .collection("products")
        .onSnapshot(querySnapshot => {
          let products = [];
          querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            self.stockincominglist.forEach(doc => {
              if (doc.id == product.id) {
                if (product.stockincoming == null) {
                  product.stockincoming = doc;
                } else {
                  let diff =
                    product.stockincoming.date.seconds - doc.date.seconds;
                  if (diff > 0) {
                    product.stockincoming = doc;
                  }
                }
              }
            });
            products.push(product);
          });
          self.productslist = products;
        });
    },
    getstockincoming: function() {
      const self = this;
      firebase
        .firestore()
        .collection("stockvoucher")
        .where("status", "==", "En Camino")
        .onSnapshot(function(querySnapshot) {
          let stockincominglist = [];
          querySnapshot.forEach(doc => {
            let stock = doc.data();
            stock.id = doc.id;
            let stockincoming = {};
            stockincoming.warehouse = stock.warehouse;
            stockincoming.date = stock.date;
            stockincoming.stock = stock;
            stock.listofproductsinvoucher.forEach(doc => {
              stockincoming.id = doc.id;
              stockincoming.quantity = doc.quantity;
              stockincominglist.push(stockincoming);
            });
          });
          self.stockincominglist = stockincominglist;
        });
    },
    deletproduct: function(product) {
      const self = this;
      const prod = product;
      this.$q
        .dialog({
          title: "Eliminar " + prod.model,
          message: "Desea eliminar este producto? ",
          ok: {
            push: true,
            label: "Confirmar"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Cancelar"
          }
        })
        .onOk(() => {
          firebase
            .firestore()
            .collection("products")
            .doc(prod.id)
            .delete()
            .then(function() {
              var storageRef = firebase.storage().ref();
              storageRef
                .child("products/" + prod.id)
                .delete()
                .catch(function(error) {
                  self.$buefy.toast.open("Error removing document: ", error);
                });
              self.$buefy.toast.open("Producto Eliminado");
              self.getproductslist();
            })
            .catch(function(error) {
              self.$buefy.toast.open("Error removing document: ", error);
            });
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    newproduct: function() {
      this.$router.push({ name: "newproduct" });
    },
    stocklist: function() {
      this.$router.push({ name: "stocklist" });
    },
    openProduct: function(product) {
      this.$router.push({ name: "viewproduct", params: { product: product } });
    },
    openWarehouses: function() {
      this.$router.push({ name: "warehouses" });
    },
    editProduct: function(product) {
      this.$router.push({ name: "editproduct", params: { product: product } });
    }
  }
};
