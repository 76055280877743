//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import axios from 'axios'
import moment from "moment";
export default {
  data() {
    return {
      client: null,
      agent: null,
      data: [],
      filter: "",
      toupdate: false,
      submitting: "",
      clientslist: [],
      agentslist: [],
      columns: [
        {
          name: "salevoucher",
          align: "left",
          label: "Código de Venta",
          field: "salevoucher",
          sortable: true
        },
        {
          name: "clientname",
          align: "left",
          label: "Cliente",
          field: "clientname",
          sortable: true
        },
        {
          name: "agentname",
          align: "left",
          label: "Agente",
          field: "agentname",
          sortable: true
        },
        {
          name: "date",
          align: "left",
          label: "Fecha",
          field: "date",
          sortable: true
        },
        { name: "actions", label: "Acciones", field: "", align: "center" }
      ]
    };
  },
  beforeMount() {
    this.getclientslist();
    this.getagentslist();
    this.getsaleslist();
  },
  methods: {
    converttimestamp: function(date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getsaleslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("sales")
        .where("status", "==", "Entregado")
        .onSnapshot(querySnapshot => {
          let sales = [];
          querySnapshot.forEach(doc => {
            let sale = doc.data();
            sale.id = doc.id;
            self.clientslist.forEach(x => {
              if (x.id == sale.clientid) {
                sale.client = x;
              }
            });
            self.agentslist.forEach(x => {
              if (x.id == sale.agentid) {
                sale.agent = x;
              }
            });
            sales.push(sale);
          });
          self.data = sales;
        });
    },
    getclientslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .onSnapshot(querySnapshot => {
          let clients = [];
          querySnapshot.forEach(doc => {
            let client = doc.data();
            client.id = doc.id;
            clients.push(client);
          });
          self.clientslist = clients;
        });
    },
    getagentslist: function() {
      const self = this;
      this.isagentsListEmpty = false;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Agente de Ventas")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.agentslist = agents;
        });
    },

    downloadWithVueResource: function(sale) {
      axios({
        url: sale.proof,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let name= "VT-"+sale.salevoucher+".pdf"
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
    },
    gotoproductlist: function() {
      this.$router.push({ name: "productslist" });
    },
    viewsale: function(sale) {
      this.$router.push({ name: "viewsale", params: { sale: sale } });
    }
  }
};
