//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  data() {
    return {
      agentsList: [],
      clientsList: [],
      date: new Date(),
      productsList: [],
      packagesList: [],
      orderList: [],
      warehousesList: [],
      warehouseid: null,
      listofproductsinvoucher: [],
      listofpackagesinvoucher: [],
      lofivcolumns: [
        {
          name: "model",
          align: "left",
          label: "Producto",
          field: "model",
          sortable: true
        },
        {
          name: "price",
          align: "left",
          label: "Precio Unitario",
          field: "price",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        },
        {
          name: "totalprice",
          align: "left",
          label: "Precio",
          field: "totalprice",
          sortable: true
        }
      ],
      lopfivcolumns: [
        {
          name: "name",
          align: "left",
          label: "Packete",
          field: "name",
          sortable: true
        },
        {
          name: "price",
          align: "left",
          label: "Precio Unitario",
          field: "price",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        },
        {
          name: "totalprice",
          align: "left",
          label: "Precio",
          field: "totalprice",
          sortable: true
        }
      ],
      productquantity: 0,
      productid: null,
      packagequantity: 0,
      packageid: null,
      price: 0,
      discount: 0,
      statuslist: ["Cotización", "Pendiente de Aprobación"],
      submitting: false,
      dense: true,
      agentid: "",
      clientid: "",
      status: "",
      salevoucherfile: null,
      salevoucher: ""
    };
  },
  computed: {
    finalprice: function() {
      let disc = (this.price * this.discount) / 100;
      return this.price - disc;
    }
  },
  beforeMount() {
    this.getproductslist();
    this.getagentslist();
    this.getclientslist();
    this.getpackageslist();
    this.getwarehouseslist();
  },
  methods: {
    getwarehouseslist: function() {
      const self = this;
      this.isWarehousesEmpty = false;
      firebase
        .firestore()
        .collection("warehouses")
        .where("enable", "==", true)
        .onSnapshot(querySnapshot => {
          let warehouses = [];
          querySnapshot.forEach(doc => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouses.push(warehouse);
          });
          self.warehousesList = warehouses;
        });
    },
    getproductslist: function() {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("products")
        .onSnapshot(querySnapshot => {
          let products = [];
          querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          self.productsList = products;
        });
    },
    getpackageslist: function() {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("packages")
        .onSnapshot(querySnapshot => {
          let packageslist = [];
          querySnapshot.forEach(doc => {
            let packageitem = doc.data();
            packageitem.id = doc.id;
            packageslist.push(packageitem);
          });
          self.packagesList = packageslist;
        });
    },
    getclientslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .onSnapshot(querySnapshot => {
          let clients = [];
          querySnapshot.forEach(doc => {
            let client = doc.data();
            client.id = doc.id;
            clients.push(client);
          });
          self.clientsList = clients;
        });
    },
    getagentslist: function() {
      const self = this;
      this.isagentsListEmpty = false;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Agente de Ventas")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.agentsList = agents;
        });
    },
    addpackagestovoucher: function() {
      if (
        this.packageid != null &&
        this.packagequantity != null &&
        this.packagequantity > 0
      ) {
        this.isproductsListEmpty = false;
        var Selectedpackage;
        const self = this;
        this.packagesList.forEach(function(item) {
          if (self.packageid == item.id) {
            Selectedpackage = item;
          }
        });
        this.price = this.price + this.packagequantity * Selectedpackage.price;
        Selectedpackage.totalprice = Number(this.price);
        Selectedpackage.quantity = Number(this.packagequantity);
        this.listofpackagesinvoucher.push(Selectedpackage);
        this.packageid = null;
        this.packagequantity = null;
      } else {
        this.$q.notify({
          message: "Ingrese los datos correctos",
          color: "negative"
        });
      }
    },
    addproducttovoucher: function() {
      if (
        this.productid != null &&
        this.productquantity != null &&
        this.productquantity > 0
      ) {
        this.isproductsListEmpty = false;
        var selected_product;
        const self = this;
        this.productsList.forEach(function(item) {
          if (self.productid == item.id) {
            selected_product = item;
          }
        });
        this.price = this.price + this.productquantity * selected_product.price;
        selected_product.totalprice = Number(this.price);
        selected_product.quantity = Number(this.productquantity);
        this.listofproductsinvoucher.push(selected_product);
        this.productid = null;
        this.productquantity = null;
      } else {
        this.$q.notify({
          message: "Ingrese los datos correctos",
          color: "negative"
        });
      }
    },
    addsale: function() {
      this.submitting = true;
      const self = this;
      let selectedwarehouse;
      this.warehousesList.forEach(x => {
        if (x.id == this.warehouseid) {
          selectedwarehouse = x;
        }
      });
      const _date=moment(String(self.date)).format('MM/DD/YYYY');
      firebase
        .firestore()
        .collection("sales")
        .add({
          salevoucher: Math.floor(Math.random() * 100000) + 1,
          date: new Date(_date),
          listofproductsinvoucher: self.listofproductsinvoucher,
          listofpackagesinvoucher: self.listofpackagesinvoucher,
          agentid: self.agentid,
          clientid: self.clientid,
          status: self.status,
          price: Number(self.finalprice),
          warehouse: selectedwarehouse
        })
        .then(function() {
          //self.uploadfile(self.productimage, doc.id);
          self.$router.push({ name: "saleslist" });
        })
        .catch(function(error) {
          self.$q.notify({
            message: "Error: " + error,
            color: "negative"
          });
        });
    },
    onReset() {
      this.date = new Date();
      this.listofproductsinvoucher = [];
      this.productquantity = 0;
      this.productid = null;
      this.submitting = false;
      this.agentid = "";
      this.clientid = null;
      this.salevoucher = null;
      this.salevoucherfile = null;
    },
    gotosaleslist: function() {
      this.$router.push({ name: "saleslist" });
    }
  }
};
