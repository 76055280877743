//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      work: {},
      site: {},
      materials: [],
      installation: {},
      installationphoto: null,
      geoposition: new firebase.firestore.GeoPoint(51.435454, 31.445456),
    };
  },
  created() {
    this.work = this.$route.params.work;
  },
  beforeMount() {
    this.getsite();
    this.getwork();
  },
  methods: {
    getNullLabel(val) {
      return val != null ? val : 0;
    },
    getsite: function () {
      const self = this;
      firebase
        .firestore()
        .collection("projectsites")
        .doc(self.work.siteid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.site = doc.data();
            self.site.id = doc.id;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    getwork: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sitework")
        .doc(self.work.id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.work = doc.data();
            self.work.id = doc.id;
            self.materials = self.work.materials;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    gotowork: function () {
      this.$router.push({
        name: "sitework",
        params: { work: this.work, site: this.site },
      });
    },
    createWorkInstallation: function () {
      const self = this;
      let materials = [];
      this.materials.forEach((mat) => {
        let material = {};
        material.id = mat.id;
        material.model = mat.model;
        material.quantity = mat.value;
        materials.push(material);
      });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          self.geoposition = new firebase.firestore.GeoPoint(
            position.coords.latitude,
            position.coords.longitude
          );
          firebase
          .firestore()
          .collection("workinstallation")
          .add({
            materials: materials,
            workid: self.work.id,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            location: self.geoposition,
          })
          .then((installation) => {
            const id = installation.id;
            self.uploadmaterial(self.installationphoto, id);
            let materialsupdated = [];
            self.work.materials.forEach((x) => {
              let material = {};
              material.id = x.id;
              material.model = x.model;
              material.quantity = x.quantity;
              material.used = x.used;
              self.materials.forEach((y) => {
                if (x.id == y.id) {
                  material.used = x.used + y.value;
                }
              });
              materialsupdated.push(material);
            });
            firebase
              .firestore()
              .collection("sitework")
              .doc(self.work.id)
              .update({
                materials: materialsupdated,
              });
            self.gotowork();
          });
        });
        
      }
    },
    uploadmaterial: function (file, docid) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("site/work/installation/" + id + ".jpg")
        .put(file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("workinstallation").doc(id).update({
              photourl: downloadURL,
            });
          });
        }
      );
    },
  },
};
