import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/users/login.vue'
import Newuser from '../views/users/newuser'
import UsersList from '../views/users/userslist'
import UserProfile from '../views/users/userprofile'
import myProfile from '../views/users/myprofile'
import ProductsList from '../views/warehouses/productslist'
import NewProduct from '../views/warehouses/newproduct'
import ViewProduct from '../views/warehouses/viewproduct'
import EditProduct from '../views/warehouses/editproduct'
import Warehouses from '../views/warehouses/warehouses'
import ViewWarehouse from '../views/warehouses/viewwarehouse'
import ViewStockWarehouse from "../components/warehouse/stock/viewstockvoucher"
import ProofOfDelivery from "../views/warehouses/proofofdelivery"
import StockList from '../views/warehouses/stocklist'
import AddStock from '../views/warehouses/addstock'
import ViewStockVoucher from '../views/warehouses/viewstockvoucher'
import ViewMaterialRequestonWarehouse from "../components/warehouse/materialrequest/viewrequest"
import PurchasesAndSales from '../views/purchasesandsales/index'
import ViewMaterialRequestonPruchasesandSales from "../components/purchasesandsales/projects/material/viewrequest"
import NewSale from '../views/purchasesandsales/sales/newsale'
import SalesList from '../views/purchasesandsales/sales/saleslist'
import ViewSale from '../views/purchasesandsales/sales/viewsale'
import Clients from "../views/purchasesandsales/sales/clients"
import Projects from "../views/projects"
import Project from "../views/projects/project"
import NewProject from "../components/projects/newproject"
import EditProject from "../components/projects/editproject"
import NewProjectSite from "../components/projects/sites/newsite"
import ViewProjectSite from "../views/projects/project/sites/viewsite"
import AddContractorToProjectSite from "../views/projects/project/sites/addcontractor"
import SiteWork from "../views/projects/project/sites/viewwork"
import FinishSiteWork from "../views/projects/project/sites/work/finish"
import AddWorkInstallation from "../views/projects/project/sites/work/installation/add"
import Contractors from "../views/projects/contractors"
import ViewContractor from "../views/projects/contractors/viewcontractor"
import ToolsAndMaterials from "../views/toolsandmaterials"
import ViewMaterialRequest from "../views/toolsandmaterials/materials/request/viewrequest"
import ViewMaterialReturn from "../views/toolsandmaterials/materials/returns/View"
import PackagesView from '../views/purchasesandsales/sales/packages'
import Marketing from '../views/marketing/index.vue'
import Acquisitions from '../views/acquisitions/index.vue'
import RRHH from '../views/rrhh/index.vue'
import Postulations from '../views/rrhh/pages/postulations.vue'
import Requests from '../views/rrhh/pages/requests.vue'
import firebase from "firebase"


Vue.use(VueRouter)

/*const ifAuthenticated = (to, from, next) => {
  if (store.getters.loggedIn) {
    next()
    return
  }
  next('/login')
}*/

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  //-->USERS
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/newuser',
    name: 'newuser',
    component: Newuser
  },
  {
    path: '/userslist',
    name: 'userslist',
    component: UsersList
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: UserProfile
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    component: myProfile
  },
  //--->END USERS
  //---->ALMACENES
  {
    path: '/productslist',
    name: 'productslist',
    component: ProductsList
  },
  {
    path: '/newproduct',
    name: 'newproduct',
    component: NewProduct
  },
  {
    path: '/viewproduct',
    name: 'viewproduct',
    component: ViewProduct
  },
  {
    path: '/editproduct',
    name: 'editproduct',
    component: EditProduct
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: Warehouses
  },
  {
    path: '/viewwarehouse',
    name: 'viewwarehouse',
    component: ViewWarehouse
  },
  {
    path: '/stocklist',
    name: 'stocklist',
    component: StockList
  },
  {
    path: '/addstock',
    name: 'addstock',
    component: AddStock
  },
  {
    path: '/viewstockvoucher',
    name: 'viewstockvoucher',
    component: ViewStockVoucher
  },
  {
    path: '/warehouse/materialrequest/view',
    name: '/warehouse/materialrequest/view',
    component: ViewMaterialRequestonWarehouse
  },
  {
    path: '/warehouse/stock/view',
    name: '/warehouse/stock/view',
    component: ViewStockWarehouse
  },

  //---->END ALMACENES
  {
    path: '/purchasesandsales',
    name: 'purchasesandsales',
    component: PurchasesAndSales
  },
  {

    path: '/purchasesandsales/materials/request/view',
    name: '/purchasesandsales/materials/request/view',
    component: ViewMaterialRequestonPruchasesandSales
  },
  //---->VENTAS
  {
    path: '/newsale',
    name: 'newsale',
    component: NewSale
  },
  {
    path: '/packagesview',
    name: 'packagesview',
    component: PackagesView
  },
  {
    path: '/saleslist',
    name: 'saleslist',
    component: SalesList
  },
  {
    path: '/sale/view',
    name: 'viewsale',
    component: ViewSale
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients
  },
  //____>END VENTAS
  //PROYECTOS
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/project/new',
    name: 'newproject',
    component: NewProject
  },
  {
    path: '/project/edit',
    name: 'editProject',
    component: EditProject
  },
  {
    path: '/project/',
    name: 'project',
    component: Project
  },
  {
    path: '/project/site/view',
    name: 'viewprojectsite',
    component: ViewProjectSite
  },
  {

    path: '/project/site/new',
    name: 'newprojectsite',
    component: NewProjectSite
  },
  {
    path: '/project/contractors/',
    name: 'contractors',
    component: Contractors
  },
  {
    path: '/project/contractors/view',
    name: 'viewcontractor',
    component: ViewContractor
  },
  {
    path: "/project/site/addcontractor",
    name: "addcontractortosite",
    component: AddContractorToProjectSite
  },

  {
    path: "/project/site/work/",
    name: "sitework",
    component: SiteWork
  },
  {
    path: "/project/site/work/finish",
    name: "finishsitework",
    component: FinishSiteWork
  },
  {
    path: "/project/site/work/installation",
    name: "addworkinstallation",
    component: AddWorkInstallation
  },
  {
    path: '/warehouse/proofofdelivery',
    name: 'proofofdelivery',
    component: ProofOfDelivery
  },
  {

    path: '/toolsandmaterials/',
    name: 'toolsandmaterials',
    component: ToolsAndMaterials
  },
  {
    path: '/toolsandmaterials/materials/request/',
    name: 'materialrequest',
    component: ViewMaterialRequest
  },
  {
    path: '/toolsandmaterials/materials/returns/View',
    name: 'viewmaterialreturns',
    component: ViewMaterialReturn
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: Marketing
  },
  {
    path: '/rrhh',
    name: 'RRHH',
    component: RRHH
  },
  
  {
    path: '/rrhh/postulaciones',
    name: 'Postulations',
    component: Postulations
  },
  
  {
    path: '/rrhh/solicitudes',
    name: 'Requests',
    component: Requests
  },
  {
    path: '/adquisiciones',
    name: 'Acquisitions',
    component: Acquisitions
  }
  //---->END PROYECTOS

  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
    //beforeEnter: ifNotAuthenticated, && to.path!=='/'
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  var user = firebase.auth().currentUser;
  if (to.name !== 'login') {
    if (user) {
      next()
    } else {
      next('/login')
    }
  } else {
    if (user) {
      next('/')
    } else {
      next()
    }
  }
})
export default router
