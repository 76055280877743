//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import newmaterialrequest from "../../../../components/toolsandmaterials/materials/newmaterialrequest";
import pendingmaterialrequest from "../../../../components/toolsandmaterials/materials/pendingmaterialrequest";
import approvedmaterialrequest from "../../../../components/toolsandmaterials/materials/approvedmaterialrequest";
export default {
  components: {
    newmaterialrequest,
    pendingmaterialrequest,
    approvedmaterialrequest
  }
};
