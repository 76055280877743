//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      materialreturns: null,
      routeback: "",
      columns: [
        {
          name: "model",
          align: "left",
          label: "Nombre",
          field: "model",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        }
      ]
    };
  },
  created() {
    this.materialreturns = this.$route.params.materialreturns;
    this.routeback = this.$route.params.routeback;
  },
  methods: {
    returntoroute: function () {
      this.$router.push({
        name: this.routeback,
      });
    },
    pintproof: function () {
      window.print();
    },
  },
};
