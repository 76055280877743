//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  name: "newproject",
  data() {
    return {
      submitting: false,
      project: {},
      clientslist: [],
      projectmanagerlist: [],
      countryoptions: ["Bolivia", "Peru", "Chile", "Argentina", "Brasil"],
      departamentoptions: ["La Paz", "Cochabamba", "Santacruz"]
    };
  },
  beforeMount() {
    this.getprojectmanagerlist();
  },
  methods: {
    getprojectmanagerlist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Project Manager")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.projectmanagerlist = agents;
        });
    },
    addproject: function() {
      const self = this;
      this.submitting = true;
      const dateinit=moment(String(self.project.dateinit)).format('MM/DD/YYYY');
      const dateend=moment(String(self.project.dateend)).format('MM/DD/YYYY');
      firebase
        .firestore()
        .collection("projects")
        .add({
          name: self.project.name,
          code: Math.floor(Math.random() * 100000) + 1, //TODO:verificar duplicado
          projectmanagerid: self.project.projectmanagerid,
          client: self.project.client,
          country: self.project.country,
          dateinit: new Date(dateinit),
          dateend: new Date(dateend),
          contract: self.project.contract,
          status: "En Proceso"
        });
      self.onReset();
      self.gotoprojects();
    },
    onReset: function() {
      this.project = {};
      this.submitting = false;
    },
    gotoprojects: function() {
      this.$router.push({ name: "projects" });
    }
  }
};
