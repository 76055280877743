//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
import { mapState } from "vuex";
import addstock from "./addstock";
export default {
  name: "login",
  data() {
    return {
      stocklist: [],
      filter: "",
      pagination: {
        sortBy: "desc",
        descending: false,
        rowsPerPage: 25,
        rowsNumber: this.userlistlenght
      },
      columns: [
        {
          name: "vouchercode",
          align: "left",
          label: "Código de Entrega",
          field: "vouchercode",
          sortable: true
        },
        {
          name: "date",
          align: "left",
          label: "Fecha de Ingreso",
          field: "date",
          sortable: true
        },
        {
          name: "status",
          align: "left",
          label: "Estado",
          field: "status",
          sortable: true
        },
        {
          name: "warehouse",
          align: "left",
          label: "Almacén",
          field: "warehouse",
          sortable: true
        },
        { name: "actions", label: "Acciones", field: "", align: "center" }
      ]
    };
  },
  components: {
    addstock
  },
  beforeMount() {
    this.getstockslist();
  },
  computed: {
    ...mapState(["profile", "islogged"])
  },
  methods: {
    converttimestamp: function(date){
      return moment(date.seconds *1000).format("DD/MM/YYYY") 
    },
    getstockslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("stockvoucher")
        .onSnapshot(querySnapshot => {
          let stocklist = [];
          querySnapshot.forEach(doc => {
            let stock = doc.data();
            stock.id = doc.id;
            stocklist.push(stock);
          });
          self.stocklist = stocklist;
        });
    },
    addstock: function(products, stockid, warehouse) {
      products.forEach(product => {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", warehouse)
          .get()
          .then(function(querySnapshot) {
            if (querySnapshot.empty) {
              firebase
                .firestore()
                .collection("stock")
                .add({
                  productid: product.id,
                  productmodel: product.model,
                  productquantity: Number(product.quantity),
                  warehouse: warehouse
                });
            } else {
              querySnapshot.forEach(function(doc) {
                let data = doc.data();
                let newquantity =
                  Number(product.quantity) + Number(data.productquantity);
                firebase
                  .firestore()
                  .collection("stock")
                  .doc(doc.id)
                  .update({
                    productquantity: Number(newquantity)
                  });
              });
            }
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then(doc => {
                let data = doc.data();
                let newstock = Number(data.stock) + Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock
                  });
              });
          });
      });
    },
    openstockvoucher: function(stockvoucher) {
      this.$router.push({
        name: "viewstockvoucher",
        params: { stockvoucher: stockvoucher }
      });
    },
    gotopurchasesandsales: function() {
      this.$router.push({ name: "purchasesandsales" });
    }
  }
};
