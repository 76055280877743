//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import { mapMutations, mapState } from "vuex";
export default {
  name: "newuser",
  data() {
    return {
      user: "",
      password: "",
      confirmpassword: "",
      roleoptions: [
        "Gerente General",
        "Jefe de Compras y Ventas",
        "Agente de Ventas",
        "Project Manager",
        "Jefe de Nacional de Almacenes",
        "Jefe de Almacen",
        "Supervisor",
        "Responsable de División Outsourcing y RRHH",
        "Jefe de marketing",
        "Especialista de adquisiciones",
      ],
    };
  },
  created() {
    this.user = this.$route.params.user;
  },

  computed: {
    ...mapState(["profile", "islogged"]),
  },
  methods: {
    ...mapMutations(["auth_mutation"]),
    updateruser: function () {
      const self = this;
      if (this.profile.id == this.user.id) {
        if (this.password == this.confirmpassword) {
          if (this.password != "") {
            var user = firebase.auth().currentUser;
            user.updatePassword(this.password).catch(function (error) {
              console.log(error);
            });
          }
        } else {
          self.$q.notify({
            message: "Contraseñas no coninciden",
            color: "negative",
            position: "bottom",
          });
        }
      }
      if (self.user.profileimage != null) {
        firebase
          .firestore()
          .collection("users")
          .doc(self.user.id)
          .update({
            name: self.user.name,
            ci: self.user.ci,
            role: self.user.role,
            email: self.user.email,
          })
          .then(function () {
            self.$q.notify({
              message: "Usuario actualizado",
              color: "positive",
              position: "bottom",
            });
            var data = self.user;
            self.uploadfile(self.user.profileimage, data.id);
            if (self.profile.email == data.email) {
              var prof = {
                id: data.id,
                name: data.name,
                email: data.email,
                role: data.role,
                ci: data.ci,
                status: data.status,
                profileImagePath: data.profileImagePath,
                islogged: true,
              };
              self.$store.commit("auth_mutation", prof);
            }
          })
          .catch(function (error) {
            self.$q.notify({
              message: error,
              color: "positive",
              position: "bottom",
            });
          });
      } else {
        firebase
          .firestore()
          .collection("users")
          .doc(self.user.id)
          .update({
            name: self.user.name,
            ci: self.user.ci,
            role: self.user.role,
            email: self.user.email,
          })
          .then(function () {
            self.$q.notify({
              message: "Usuario actualizado",
              color: "positive",
              position: "bottom",
            });
            var data = self.user;
            if (self.profile.email == data.email) {
              var prof = {
                id: data.id,
                name: data.name,
                email: data.email,
                role: data.role,
                ci: data.ci,
                status: data.status,
                islogged: true,
              };
              self.$store.commit("auth_mutation", prof);
            }
          })
          .catch(function (error) {
            self.$q.notify({
              message: error,
              color: "positive",
              position: "bottom",
            });
          });
      }
    },
    uploadfile: function (file, productid) {
      //const self = this;
      const pid = productid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("users/" + pid + ".jpg")
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("users").doc(pid).update({
              profileImagePath: downloadURL,
            });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    gotousers: function () {
      this.$router.push({ name: "userslist" });
    },
  },
};
