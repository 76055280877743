//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import firebase from "firebase";
export default {
  name: "App",
  data() {
    return {
      right: false,
    };
  },
  computed: {
    ...mapState(["profile", "islogged"]),
  },
  methods: {
    ...mapMutations(["auth_mutation"]),
    openProfile: function () {
      this.$router.push({
        name: "myprofile",
        params: { user: this.profile },
      });
    },
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          var profile = { islogged: false };
          this.$store.commit("auth_mutation", profile);
          this.$router.push({
            name: "login",
          });
        });
    },
  },
};
