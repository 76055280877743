//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  data() {
    return {
      product: null,
      productimage: null,
      warehousesstock: [],
      stockincominglist: [],
      incomingcolumns: [
        {
          name: "warehouse",
          align: "left",
          label: "Almacén",
          field: "warehouse",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        },
        {
          name: "date",
          align: "left",
          label: "Fecha de arrivo",
          field: "date",
          sortable: true
        },
        {
          name: "stock",
          align: "left",
          label: "Comprobante",
          field: "stock",
          sortable: true
        }
      ],
      columns: [
        {
          name: "warehouse",
          align: "left",
          label: "Almacén",
          field: "warehouse",
          sortable: true
        },
        {
          name: "productquantity",
          align: "left",
          label: "Cantidad",
          field: "productquantity",
          sortable: true
        }
      ]
    };
  },
  created() {
    this.product = this.$route.params.product;
  },
  beforeMount() {
    this.getwarehousesstock();
    this.getincomingstock();
  },
  methods: {
    converttimestamp: function(date){
      return moment(date.seconds *1000).format("DD/MM/YYYY") 
    },
    getwarehousesstock: function() {
      const self = this;
      firebase
        .firestore()
        .collection("stock")
        .where("productid", "==", this.product.id)
        .get()
        .then(function(querySnapshot) {
          let warehousesstock = [];
          querySnapshot.forEach(doc => {
            let stock = doc.data();
            stock.id = doc.id;
            warehousesstock.push(stock);
          });
          self.warehousesstock = warehousesstock;
        });
    },
    getincomingstock: function() {
      const self = this;
      firebase
        .firestore()
        .collection("stockvoucher")
        .where("status", "==", "En Camino")
        .get()
        .then(function(querySnapshot) {
          let stockincominglist = [];
          querySnapshot.forEach(doc => {
            let stock = doc.data();
            stock.id = doc.id;
            let stockincoming = {};
            stockincoming.warehouse = stock.warehouse;
            stockincoming.date = stock.date;
            stockincoming.stock = stock;
            stock.listofproductsinvoucher.forEach(doc => {
              if (doc.id == self.product.id) {
                stockincoming.id = doc.id;
                stockincoming.quantity = doc.quantity;
                stockincominglist.push(stockincoming);
              }
            });
          });
          self.stockincominglist = stockincominglist;
        });
    },
    gotoproductlist: function() {
      this.$router.push({ name: "purchasesandsales" });
    }
  }
};
