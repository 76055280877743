//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import newmaterialreturns from "../../../../components/toolsandmaterials/materials/returns/newmaterialreturns";
import pendingmaterialreturns from "../../../../components/toolsandmaterials/materials/returns/pendingmaterialreturns";
import approvedmaterialreturns from "../../../../components/toolsandmaterials/materials/returns/approvedmaterialreturns";
export default {
  components: {
    newmaterialreturns,
    approvedmaterialreturns,
    pendingmaterialreturns
  }
};
