//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      contractor: {},
      submitting: false,
      contractorslist: [],
      filter: "",
      columns: [
        {
          name: "name",
          align: "left",
          label: "Nombre de Emrpesa",
          field: "name",
          sortable: true
        },
        {
          name: "legal_representative",
          align: "left",
          label: "Representante Legal",
          field: "legal_representative",
          sortable: true
        },
        {
          name: "phone",
          align: "left",
          label: "Nro. de Contacto",
          field: "phone",
          sortable: true
        },
        {
          name: "actions",
          align: "left",
          label: "Acciones",
          field: "actions",
          sortable: true
        }
      ]
    };
  },
  beforeMount() {
    this.getcontractors();
  },
  methods: {
    newContractor: function() {
      this.submitting = true;
      const self=this;
      self.contractor.status=true;
      firebase
        .firestore()
        .collection("contractors")
        .add(self.contractor)
        .then(() => {
          this.onReset();
        });
      this.onReset();
    },
    onReset: function() {
      this.contractor = {};
      this.submitting = false;
    },
    getcontractors: function() {
      const self = this;
      firebase
        .firestore()
        .collection("contractors")
        .onSnapshot(querySnapshot => {
          let contractors = [];
          querySnapshot.forEach(doc => {
            let contractor = doc.data();
            contractor.id = doc.id;
            contractors.push(contractor);
          });
          self.contractorslist = contractors;
        });
    },
    editcontractor: function(param) {
      this.contractor = param;
    },
    disablecontractor: function(params) {
      firebase
        .firestore()
        .collection("contractors")
        .doc(params.id)
        .update({
          status: false
        });
    },
    gotoprojects: function() {
      this.$router.push({ name: "projects" });
    },
    viewcontractor: function (params) {
      this.$router.push({ name: "viewcontractor" ,params: { contractor: params }});
    }
  }
};
