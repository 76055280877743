//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openURL } from "quasar";
import firebase from "firebase";
export default {
  data() {
    return {
      headers: [
        {
          label: "Nombres",
          align: "start",
          sortable: true,
          field: (row) => row.name,
          name: "name",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Apellidos",
          sortable: true,
          align: "start",
          field: (row) => row.lastname,
          name: "lastname",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Email",
          sortable: true,
          align: "start",
          field: (row) => row.email,
          name: "email",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Área",
          sortable: true,
          align: "start",
          field: (row) => row.area,
          name: "area",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Ciudad",
          sortable: true,
          align: "start",
          field: (row) => row.city,
          name: "city",
          headerClasses: "bg-primary text-white text-left",
        },
      ],
      filter: "",
      data: [],
      dialog: false,
      selected_data: {},
    };
  },
  beforeMount() {
    this.getpostulations_list();
  },
  methods: {
    openURL,
    getpostulations_list: function () {
      const self = this;
      firebase
        .firestore()
        .collection("outsourcing_looking_job")
        .onSnapshot((querySnapshot) => {
          let requests = [];
          querySnapshot.forEach((doc) => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    onRowClick: function (evt, row) {
      this.dialog = true;
      this.selected_data = row;
    },
  },
};
