//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import firebase from "firebase";
export default {
  data() {
    return {
      sale: {},
      storekeeper: {},
      agent: {},
      columns: [
        {
          name: "item",
          required: true,
          label: "Item",
          align: "left",
          field: (row) => row.item,
          format: (val) => `${val}`,
          sortable: true,
          classes: "bg-grey-2 ellipsis",
          style: "max-width: 50px",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "photo",
          required: true,
          label: "FOTOGRAFÍA",
          align: "left",
          field: (row) => row.productImagePath,
          format: (val) => `${val}`,
          sortable: true,
          classes: "bg-grey-2 ellipsis",
          style: "max-width: 100px",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "model",
          required: true,
          label: "NOMBRE(PAQUETE)",
          align: "left",
          field: (row) => row.model,
          format: (val) => `${val}`,
          sortable: true,
          classes: "bg-grey-2 ellipsis",
          style: "max-width: 100px",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "unit",
          required: true,
          label: "Unidad",
          align: "left",
          field: (row) => row.unit,
          format: (val) => `${val}`,
          sortable: true,
          classes: "bg-grey-2 ellipsis",
          style: "max-width: 50px",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "quantity",
          required: true,
          label: "Cantidad",
          align: "left",
          field: (row) => row.quantity,
          format: (val) => `${val}`,
          sortable: true,
          classes: "bg-grey-2 ellipsis",
          style: "max-width: 50px",
          headerClasses: "bg-primary text-white",
        },
      ],
      pagination: {
        rowsPerPage: 0,
      },
      products: [],
      orderlist: [],
      item: 0,
    };
  },
  beforeMount() {
    this.getinfo();
  },
  created() {
    this.sale = this.$route.params.sale;
  },
  methods: {
    pintproof: function () {
      window.print();
    },
    getinfo: function () {
      const self = this;
      self.sale.listofproductsinvoucher.forEach((x) => {
        self.item = self.item + 1;
        let prod = {};
        prod.item = self.item;
        prod.productImagePath = x.productImagePath;
        prod.model = x.model;
        prod.description = x.description;
        prod.unit = x.unit;
        prod.quantity = x.quantity;
        prod.price = x.price;
        prod.totalprice = x.totalprice;
        self.orderlist.push(prod);
      });
      self.sale.listofpackagesinvoucher.forEach((y) => {
        y.products.forEach((x) => {
        self.item = self.item + 1;
        let prod = {};
        prod.item = self.item;
        prod.productImagePath = x.productImagePath;
        prod.model = x.model+"("+y.name+")";
        prod.description = x.description;
        prod.unit = x.unit;
        prod.quantity = x.quantity*y.quantity;
        prod.price = x.price;
        prod.totalprice = x.totalprice;
        self.orderlist.push(prod);
      });
      });
      firebase
        .firestore()
        .collection("users")
        .doc(self.sale.warehouse.warehousemanagerid)
        .get()
        .then((x) => {
          self.storekeeper = x.data();
        });
      firebase
        .firestore()
        .collection("users")
        .doc(self.sale.agentid)
        .get()
        .then((x) => {
          self.agent = x.data();
        });
    },
    gotoviewwarehouse: function () {
      this.$router.push({
        name: "viewwarehouse",
        params: { warehouse: this.sale.warehouse },
      });
    },
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
  },
};
