//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      headers: [
        {
          label: "Empresa",
          align: "start",
          sortable: true,
          field: (row) => row.name,
          name: "name",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Contacto",
          sortable: true,
          align: "start",
          field: (row) => row.contact_name,
          name: "contact_name",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Email",
          sortable: true,
          align: "start",
          field: (row) => row.contact_email,
          name: "contact_email",
          headerClasses: "bg-primary text-white text-left",
        },
      ],
      filter: "",
      data: [],
      dialog: false,
      selected_data: {},
    };
  },
  beforeMount() {
    this.getacquisitions_list();
  },
  methods: {
    getacquisitions_list: function () {
      const self = this;
      firebase
        .firestore()
        .collection("outsourcing_be_supplier")
        .onSnapshot((querySnapshot) => {
          let requests = [];
          querySnapshot.forEach((doc) => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    onRowClick: function (evt, row) {
      this.dialog = true;
      this.selected_data = row;
    },
  },
};
