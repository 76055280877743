//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import firebase from "firebase";
import { mapState } from "vuex";
import aprovedsales from "./approvedsales";
import rejectedsales from "./rejectedsales";
import pendingsales from "./pendingsales";
import salesrequest from "./requestsales";
import deliveredsales from "./deliveredsales";
import pendingofdelivery from "./pendingofdelivery"
export default {
  data() {
    return {
      tab: "Aprobado"
    };
  },
  components: {
    aprovedsales,
    rejectedsales,
    pendingsales,
    salesrequest,
    deliveredsales,
    pendingofdelivery
  },
  computed: {
    ...mapState(["profile", "islogged"])
  },
  methods: {
    gotonewsale: function() {
      this.$router.push({ name: "newsale" });
    },
    
    gotoclients: function() {
      this.$router.push({ name: "clients" });
    }
  }
};
