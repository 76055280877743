//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SalesList from "./sales/saleslist";
import PurchasesList from "./purchases";
import ProjectsList from "./projects";
export default {
  data() {
    return {
      tab: "Purchases",
    };
  },
  components: {
    SalesList,
    PurchasesList,
    ProjectsList,
  },
};
