//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import firebase from "firebase";
import sites from "./sites/index";
import productslist from "../../../components/projects/productslist";
import { mapState } from "vuex";
export default {
  name: "newproject",
  data() {
    return {
      project: {},
      projectmanager: {},
      p: {},
      tab: "sites",
    };
  },
  components: {
    sites,
    productslist,
  },
  created() {
    this.p = this.$route.params.project;
  },
  beforeMount() {
    this.getProject();
  },
  computed: {
    ...mapState(["profile", "islogged"]),
  },
  methods: {
    getProject: function () {
      const self = this;
      firebase
        .firestore()
        .collection("projects")
        .doc(self.p.id)
        .onSnapshot(function (doc) {
          if (doc.exists) {
            //
            self.project = doc.data();
            self.project.id = doc.id;
            firebase
              .firestore()
              .collection("users")
              .doc(self.project.projectmanagerid)
              .get()
              .then((data) => {
                self.projectmanager = data.data();
                self.projectmanager.id = data.id;
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    finishproject: function () {
      const self = this;
      this.$q
        .dialog({
          title: "Confirmar",
          message: "Quiere finalizar el proyecto?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          firebase
            .firestore()
            .collection("projectsites")
            .where("projectid", "==", self.project.id)
            .where("status", "==", "En Proceso")
            .get()
            .then(function (querySnapshot) {
              if (querySnapshot.empty) {
                firebase
                  .firestore()
                  .collection("projects")
                  .doc(self.project.id)
                  .update({
                    status: "Finalizado",
                  });
              } else {
                self.$q.notify({
                  message: "No se puede finalizar el proyecto.",
                  caption: "Sitios aun en proceso",
                  color: "warning",
                });
              }
            })
            .catch(function (error) {
              console.log("Error getting documents: ", error);
            });
        })
        .onOk(() => {
          // console.log('>>>> second OK catcher')
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    gotonewsite: function (params) {
      this.$router.push({
        name: "newprojectsite",
        params: { project: params },
      });
    },
    gotoprojects: function () {
      this.$router.push({
        name: "projects",
      });
    },
  },
};
