//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  props: ["warehouse"],
  data() {
    return {
      filter: "",
      data: [],
      addproof: false,
      proofofreception: null,
      columns: [
        {
          name: "code",
          label: "Código",
          field: row => row.site.name,
          sortable: true,
          align: "left"
        },
        {
          name: "projectname",
          label: "Proyecto",
          field: row => row.project.name,
          sortable: true,
          align: "left"
        },
        { name: "sitename", label: "Sitio", field: "sitename", align: "left" },
        {
          name: "actions",
          align: "center",
          label: "Acciones",
          field: "actions",
          sortable: true
        }
      ]
    };
  },
  beforeMount() {
    this.getapprovedmaterialrequests();
  },
  methods: {
    getapprovedmaterialrequests: function() {
      const self = this;
      firebase
        .firestore()
        .collection("sitematerialreturn")
        .where("status", "==", 2) //TODO:Add Warehouse
        .onSnapshot(querySnapshot => {
          let requests = [];
          querySnapshot.forEach(doc => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    approveRequest: function(row) {
      firebase
        .firestore()
        .collection("sitematerialreturn")
        .doc(row.id)
        .update({
          status: 3
        });
      this.addstock(row.site.id, this.warehouse);
      this.closesite(row.site.id);
      this.uploadfile(this.proofofreception, row.id, "sitematerialreturn");
    },
    closesite: function(siteid) {
      firebase
        .firestore()
        .collection("projectsites")
        .doc(siteid)
        .update({
          status: "Finalizado"
        });
    },
    reducematerialstosite: function(siteid) {
      firebase
        .firestore()
        .collection("sitematerials")
        .where("siteid", "==", siteid)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            let x = doc.data();
            x.id = doc.id;
            firebase
              .firestore()
              .collection("sitematerials")
              .doc(x.id)
              .update({
                productquantity: x.used
              });
          });
        });
    },
    addstock: function(siteid, warehouse) {
      const self = this;
      firebase
        .firestore()
        .collection("sitematerials")
        .where("siteid", "==", siteid)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            let x = doc.data();
            x.id = doc.id;
            let quantity = x.productquantity - x.used;
            firebase
              .firestore()
              .collection("stock")
              .where("productid", "==", x.productid)
              .where("warehouse", "==", warehouse.name)
              .get()
              .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                  let data = doc.data();
                  data.id = doc.id;
                  let newquantity =
                    Number(data.productquantity) + Number(quantity);
                  firebase
                    .firestore()
                    .collection("stock")
                    .doc(data.id)
                    .update({
                      productquantity: Number(newquantity)
                    });
                  firebase
                    .firestore()
                    .collection("products")
                    .doc(data.productid)
                    .get()
                    .then(x => {
                      let y = x.data();
                      y.id=x.id;
                      let newstock = Number(y.stock) + Number(quantity);
                      firebase
                        .firestore()
                        .collection("products")
                        .doc(y.id)
                        .update({
                          stock: newstock
                        });
                    });
                });
                self.reducematerialstosite(siteid);
                self.onReset();
              });
          });
        });
    },
    uploadfile: function(file, docid, ext) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef.child(ext + "/" + id + ".pdf").put(file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            firebase
              .firestore()
              .collection(ext)
              .doc(id)
              .update({
                proof: downloadURL
              });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    viewRequest: function(row) {
      this.$router.push({
        name: "viewmaterialreturns",
        params: { materialreturns: row,routeback:"warehouses" }
      });
    },
    onReset: function() {
      this.addproof = false;
      this.proofofreception = null;
    }
  }
};
