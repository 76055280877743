//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      headers: [
        {
          label: "Empresa",
          align: "start",
          sortable: true,
          field: (row) => row.company,
          name: "company",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "División",
          sortable: true,
          align: "start",
          field: (row) => row.division,
          name: "division",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Email",
          align: "start",
          field: (row) => row.email,
          sortable: true,
          name: "email",
          headerClasses: "bg-primary text-white text-left",
        },
        {
          label: "Teléfono",
          align: "start",
          field: (row) => row.phone,
          name: "phone",
          headerClasses: "bg-primary text-white text-left",
        },
      ],
      filter: "",
      data: [],
      contact_list: [],
      division: null,
      divisionList: [],
      divisions: [
        {
          title: "DitCarrier",
          areas: [
            "Fibra óptica",
            "Energía",
            "Obras civiles",
            "Microondas y Radio Bases",
            "Transmisión",
          ],
        },
        {
          title: "DitEnterprise",
          areas: [
            "Cableado estructurado",
            "Fibra óptica",
            "Redes, seguridad y transmisión",
            "Obras civiles",
            "Proyectos dedicados",
            "Energía",
          ],
        },
        {
          title: "DitTransporte",
          areas: [
            "Planificación de requerimientos",
            "Procesos de inventario",
            "Soporte técnico",
            "Almacenaje seguro",
            "Horarios flexibles",
            "Personal calificado",
          ],
        },
        {
          title: "DitMantenimiento",
          areas: [
            "Redes FTTH",
            "Obras civiles",
            "Red de transporte",
            "Transmisión",
          ],
        },
        {
          title: "DitOutsourcing",
          areas: [
            "Reclutamiento ",
            "Selección",
            "Evaluación",
            "Contratación",
            "Administración de nómina",
            "Postular a un empleo",
          ],
        },
      ],
      AreaList: [],
      area: "Seleccione una División",
      dialog: false,
      selected_data: {},
    };
  },
  beforeMount() {
    this.getContact_list();
    this.setDivisions();
  },
  watch: {
    division: function () {
      let AreaList = [];

      if (this.division != null) {
        this.divisions.forEach((x) => {
          if (x.title === this.division) {
            AreaList = x.areas;
            this.AreaList = AreaList;
          }
        });
        this.data = [];
        this.contact_list.forEach((y) => {
          if (y.division === this.division) {
            this.data.push(y);
          }
        });
        this.area = "Seleccione una División";
      } else {
        this.data = this.contact_list;
        this.AreaList = [];
        this.area = "Seleccione una División";
      }
    },
    area: function () {
      if (this.area != "Seleccione una División") {
        this.data = [];
        this.contact_list.forEach((x) => {
          if (x.area === this.area && x.division === this.division) {
            this.data.push(x);
          }
        });
      }
    },
  },
  methods: {
    getContact_list: function () {
      const self = this;
      firebase
        .firestore()
        .collection("marketing_contact")
        .onSnapshot((querySnapshot) => {
          let requests = [];
          querySnapshot.forEach((doc) => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.contact_list = requests;
          self.data = self.contact_list;
        });
    },
    setDivisions: function () {
      const self = this;
      this.divisions.forEach((division) => {
        self.divisionList.push(division.title);
      });
    },
    resetFilters: function () {
      this.filter = "";
      this.division = null;
      (this.area = "Seleccione una División"), (this.data = this.contact_list);
    },
    onRowClick: function (evt, row) {
      this.dialog = true;
      this.selected_data = row;
    },
  },
};
