//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      filter: "",
      data: [],
      columns: [
        {
          name: "code",
          label: "Código",
          field: "code",
          sortable: true,
          align: "left"
        },
        {
          name: "projectname",
          label: "Proyecto",
          field: "projectname",
          sortable: true,
          align: "left"
        },
        { name: "sitename", label: "Sitio", field: "sitename", align: "left" },
        {
          name: "actions",
          align: "center",
          label: "Acciones",
          field: "actions",
          sortable: true
        }
      ]
    };
  },
  beforeMount() {
    this.getapprovedmaterialrequests();
  },
  methods: {
    getapprovedmaterialrequests: function() {
      const self = this;
      firebase
        .firestore()
        .collection("materialrequest")
        .where("status", "==", "En Proceso")
        .onSnapshot(querySnapshot => {
          let requests = [];
          querySnapshot.forEach(doc => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    approveRequest: function(row) {
      firebase
        .firestore()
        .collection("materialrequest")
        .doc(row.id)
        .update({
          status: "Aprobado"
        });
      //this.addmaterialstosite(row.listofproducts, row.siteid, row.projectid);
      //this.reducestock(row.listofproducts, row.warehouse);
    },
    addmaterialstosite: function(listofproducts, siteid,projectid){
      listofproducts.forEach(product=>{
        firebase
          .firestore()
          .collection("sitematerials")
          .where("productid", "==", product.id)
          .where("siteid", "==", siteid)
          .get()
          .then(function(querySnapshot) {
            if (querySnapshot.empty) {
              firebase
                .firestore()
                .collection("sitematerials")
                .add({
                  productid: product.id,
                  productmodel: product.model,
                  productquantity: Number(product.quantity),
                  siteid: siteid,
                  projectid:projectid
                });
            }
            else{
              querySnapshot.forEach(function(doc) {
                let data = doc.data();
                let newquantity =
                  Number(product.quantity) + Number(data.productquantity);
                firebase
                  .firestore()
                  .collection("sitematerials")
                  .doc(doc.id)
                  .update({
                    productquantity: Number(newquantity)
                  });
              });
            }

        });
      });
    },
    reducestock: function(listofproducts,warehouse) {
      listofproducts.forEach(product => {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", warehouse.name)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let data = doc.data();
              let newquantity =
                Number(data.productquantity) - Number(product.quantity);
              firebase
                .firestore()
                .collection("stock")
                .doc(doc.id)
                .update({
                  productquantity: Number(newquantity)
                });
            });
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then(doc => {
                let data = doc.data();
                let newstock = Number(data.stock) - Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock
                  });
              });
          });
      });
    },
    rejectRequest: function(row) {
      firebase
        .firestore()
        .collection("materialrequest")
        .doc(row.id)
        .update({
          status: "Rechazado"
        });
    },
    viewRequest: function(row) {
      this.$router.push({
        name: "/purchasesandsales/materials/request/view",
        params: { materialrequest: row }
      });
    }
  }
};
