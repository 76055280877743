//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      returns: [],
      columns: [
        {
          name: "name",
          align: "left",
          label: "Nombre",
          field: "name",
          sortable: true,
        },
        {
          name: "warehouse",
          align: "left",
          label: "Almacén",
          field: "warehouse",
          sortable: true,
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true,
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
      filter: "",
      card: false,
      data: {},
    };
  },
  beforeMount() {
    this.getreturns();
  },
  methods: {
    getreturns: function () {
      const self = this;
      firebase
        .firestore()
        .collection("returnstock")
        .where("status", "==", false)
        .onSnapshot(function (querySnapshot) {
          var r = [];
          querySnapshot.forEach(function (doc) {
            let ret = doc.data();
            ret.id = doc.id;
            r.push(ret);
          });
          self.returns = r;
        });
    },
    viewdetail: function (data) {
      self.data = data;
      self.card = true;
      this.$q
        .dialog({
          title: "Reducir "+data.quantity+" unidades de "+data.product.productmodel,
          message: "Razon: "+data.reason,
        })
        .onOk(() => {
          // console.log('OK')
        })
        .onCancel(() => {
          // console.log('Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    reducestock: function (data) {
      const request = data;
      const self = this;
      firebase
        .firestore()
        .collection("stock")
        .where("productid", "==", request.product.productid)
        .where("warehouse", "==", request.warehouse)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            if (data.warehouse == request.warehouse) {
              let newquantity =
                Number(data.productquantity) - Number(request.quantity);
              firebase
                .firestore()
                .collection("stock")
                .doc(data.id)
                .update({
                  productquantity: newquantity,
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection("products")
                    .doc(request.product.productid)
                    .get()
                    .then((doc) => {
                      let data = doc.data();
                      let newstock =
                        Number(data.stock) - Number(request.quantity);
                      firebase
                        .firestore()
                        .collection("products")
                        .doc(request.product.productid)
                        .update({
                          stock: newstock,
                        })
                        .then(() => {
                          firebase
                            .firestore()
                            .collection("returnstock")
                            .doc(request.id)
                            .update({
                              status: true,
                            })
                            .then(() => {
                              self.$q.notify({
                                message: "Se desconto el stock correctamente.",
                                color: "success",
                              });
                            });
                        });
                    });
                });
            }
          });
        });
    },
  },
};
