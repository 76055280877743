//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  props: ["projectid"],
  data() {
    return {
      filter: "",
      columns: [
        {
          name: "name",
          label: "Nombre",
          field: "name",
          sortable: true,
          align: "left"
        },
        {
          name: "supervisorname",
          label: "Supervisor",
          field: "supervisorname",
          sortable: true,
          align: "left"
        },
        {
          name: "status",
          label: "Estado",
          field: "status",
          sortable: true,
          align: "left"
        },
        {
          name: "date_init",
          label: "Inicio",
          field: "date_init",
          align: "left"
        },
        {
          name: "date_end",
          label: "Finalización",
          field: "date_end",
          align: "left"
        }
      ],
      data: [],
      supervisorslist: []
    };
  },
  beforeMount() {
    this.getsupervisors();
    this.getsites();
  },
  methods: {
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getsupervisors: function() {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Supervisor")
        .onSnapshot(querySnapshot => {
          let supervisors = [];
          querySnapshot.forEach(doc => {
            let supervisor = doc.data();
            supervisor.id = doc.id;
            supervisors.push(supervisor);
          });
          self.supervisorslist = supervisors;
        });
    },
    getsites: function() {
      const self = this;
      const projectid = self.projectid;
      firebase
        .firestore()
        .collection("projectsites")
        .where("projectid", "==", projectid)
        .onSnapshot(querySnapshot => {
          let sites = [];
          querySnapshot.forEach(doc => {
            let site = doc.data();
            site.id = doc.id;
            self.supervisorslist.forEach(x => {
              if (x.id == site.supervisorid) {
                site.supervisorname = x.name;
              }
            });
            sites.push(site);
          });
          self.data = sites;
        });
    },
    openSite: function(site) {
      this.$router.push({ name: "viewprojectsite",params: { site: site } });
    }
  }
};
