import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profile: null,
    islogged: false,
  },
  mutations: {
    auth_mutation(state, profile) {
      if (profile.islogged) {
        state.profile = { 'id': profile.id, 'name': profile.name, 'email': profile.email, 'role': profile.role, 'profileImagePath': profile.profileImagePath, 'status': profile.status, 'ci': profile.ci }
      } else {
        state.profile = null
      }

      state.islogged = profile.islogged
    }
  },
  actions: {
  },
  modules: {
  }
})
