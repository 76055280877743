//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      work: {},
      site: {},
      materials: [],
      devolution: {},
      notephoto: null,
      materialphoto: null,
      columns: [
        {
          name: "model",
          align: "left",
          label: "Material",
          field: "model",
        },
        {
          name: "quantity",
          align: "left",
          label: "Asignado",
          field: "quantity",
        },
        {
          name: "used",
          align: "left",
          label: "Usado",
          field: "used",
        },
        {
          name: "remainder",
          align: "center",
          label: "Devolver",
          field: "remainder",
        },
      ],
      geoposition: new firebase.firestore.GeoPoint(51.435454, 31.445456),
    };
  },
  created() {
    this.work = this.$route.params.work;
  },
  beforeMount() {
    this.getsite();
    this.getwork();
  },
  methods: {
    getsite: function () {
      const self = this;
      firebase
        .firestore()
        .collection("projectsites")
        .doc(self.work.siteid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.site = doc.data();
            self.site.id = doc.id;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    getwork: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sitework")
        .doc(self.work.id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.work = doc.data();
            self.work.id = doc.id;
            self.materials = self.work.materials;
            self.materials.forEach((material) => {
              material.remainder = material.quantity - material.used;
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    gotowork: function () {
      this.$router.push({
        name: "sitework",
        params: { work: this.work, site: this.site },
      });
    },
    finishwork: function () {
      const self = this;
      let list = [];
      let newmaterialsinwork = [];
      this.materials.forEach((material) => {
        let mat = {};
        mat.id = material.id;
        mat.model = material.model;
        mat.quantity = material.remainder;
        list.push(mat);
        let x = {};
        x.id = material.id;
        x.model = material.model;
        x.quantity = material.quantity - material.remainder;
        x.remainder=material.remainder;
        x.used = material.used;
        newmaterialsinwork.push(x);
        firebase
          .firestore()
          .collection("sitematerials")
          .where("siteid", "==", self.site.id)
          .where("productid", "==", x.id)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              let material= doc.data();
              material.id=doc.id;
              let new_used=Number(material.used)-Number(x.remainder);
              firebase
                .firestore()
                .collection("sitematerials")
                .doc(doc.id)
                .update({ used: new_used });
            });
          });
      });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          self.geoposition = new firebase.firestore.GeoPoint(
            position.coords.latitude,
            position.coords.longitude
          );
          firebase
            .firestore()
            .collection("returnworkremainder")
            .add({
              materials: list,
              workid: self.work.id,
              siteid: self.site.id,
              created: firebase.firestore.FieldValue.serverTimestamp(),
              location: self.geoposition,
            })
            .then((data) => {
              self.uploadnote(self.notephoto, data.id);
              self.uploadmaterial(self.materialphoto, data.id);
              firebase
                .firestore()
                .collection("sitework")
                .doc(self.work.id)
                .update({
                  materials: newmaterialsinwork,
                  status: false,
                })
                .then(() => {
                  self.gotowork();
                });
            });
        });
      }
    },
    uploadnote: function (file, docid) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("site/work/finish/" + id + "/note.jpg")
        .put(file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase
              .firestore()
              .collection("returnworkremainder")
              .doc(id)
              .update({
                noteurl: downloadURL,
              });
          });
        }
      );
    },
    uploadmaterial: function (file, docid) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("site/work/finish/" + id + "/materials.jpg")
        .put(file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase
              .firestore()
              .collection("returnworkremainder")
              .doc(id)
              .update({
                materialurl: downloadURL,
              });
          });
        }
      );
    },
  },
};
