//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      materialrequest: null,
      projectmanager: {},
      columns: [
        {
          name: "model",
          align: "left",
          label: "Nombre",
          field: "model",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        }
      ]
    };
  },
  created() {
    this.materialrequest = this.$route.params.materialrequest;
  },
  beforeMount() {
    this.getprojectmanager();
  },
  methods: {
    getprojectmanager: function() {
      const self = this;
      firebase
        .firestore()
        .collection("users")
        .doc(this.materialrequest.projectmanagerid)
        .get()
        .then(x => {
          self.projectmanager = x.data();
          self.projectmanager.id = x.id;
        });
    },
    pintproof: function() {
      window.print();
    },
    goback: function() {
      this.$router.push({
        name: "purchasesandsales"
      });
    }
  }
};
