//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AceptedMaterialRequest from "../../../components/purchasesandsales/projects/material/aceptedrequest";
import PendingMaterialRequest from "../../../components/purchasesandsales/projects/material/pendingrequest";
import RejectMaterialRequest from "../../../components/purchasesandsales/projects/material/rejectrequest";
import DeliveredRequest from "../../../components/purchasesandsales/projects/material/deliveredrequest";
import AceptedMaterialReturns from "../../../components/purchasesandsales/projects/material/returns/approvedmaterialreturns";
import PendingMaterialReturns from "../../../components/purchasesandsales/projects/material/returns/pendingmaterialreturns";
export default {
  data() {
    return {
      tab: "requests",
      expanded: true
    };
  },
  components: {
    AceptedMaterialRequest,
    PendingMaterialRequest,
    RejectMaterialRequest,
    DeliveredRequest,
    AceptedMaterialReturns,
    PendingMaterialReturns
  }
};
