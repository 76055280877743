//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import { mapState } from "vuex";
import newuser from "./newuser";
export default {
  name: "login",
  data() {
    return {
      userslist: [],

      userlistlenght: 0,
      loading: true,
      isconfirmuserdisable: false,
      isconfirmuserenable: false,
      filter: "",
      pagination: {
        sortBy: "desc",
        descending: false,
        rowsPerPage: 25,
        rowsNumber: this.userlistlenght,
      },
      columns: [
        {
          name: "ImageProfile",
          align: "left",
          label: "Fotografia",
          field: "profileImagePath",
          sortable: true,
        },
        {
          name: "name",
          required: true,
          label: "Nombre",
          align: "left",
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "ci",
          align: "left",
          label: "C.I.",
          field: "ci",
          sortable: true,
        },
        {
          name: "role",
          align: "left",
          label: "Rol",
          field: "role",
          sortable: true,
        },
        {
          name: "status",
          align: "left",
          label: "Estado",
          field: "status",
          sortable: true,
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
      isnewuser: false,
    };
  },
  beforeMount() {
    this.getuserslist();
  },
  computed: {
    ...mapState(["profile", "islogged"]),
  },
  components: {
    newuser,
  },
  methods: {
    getuserslist: function () {
      const self = this;
      this.isEmpty = false;
      firebase
        .firestore()
        .collection("users")
        .onSnapshot((querySnapshot) => {
          let users = [];
          querySnapshot.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            users.push(user);
          });
          self.userslist = users;
          self.userlistlenght = users.length;
        });
      this.loading = false;
    },
    confirmuserenable: function (user) {
      var uid = user.id;
      const self = this;
      this.$q
        .dialog({
          title: "Confirmar",
          message: "Desea habilitar a " + user.name,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
            color: "negative",
          },
        })
        .onOk(() => {
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({ status: true })
            .then(function () {
              self.$q.notify({
                message: "Usuario Habilitado!",
                color: "primary",
                position: "top",
              });
            })
            .catch(function (error) {
              self.$q.notify({
                message: "error" + error,
                color: "warning",
              });
            });
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    confirmuserdisable: function (user) {
      var uid = user.id;
      const self = this;
      this.$q
        .dialog({
          title: "Confirmar",
          message: "Desea deshabilitar a " + user.name,
          ok: {
            push: true,
            label: "Confirmar",
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Cancelar",
          },
        })
        .onOk(() => {
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({ status: false })
            .then(function () {
              self.$q.notify({
                message: "Usuario deshabilitado!",
                color: "primary",
                position: "top",
              });
            })
            .catch(function (error) {
              self.$q.notify({
                message: "error" + error,
                color: "warning",
              });
            });
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    openProfile: function (user) {
      this.$router.push({ name: "userprofile", params: { user: user } });
    },
  },
};
