//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectslist from "../../components/projects/projectslist";
export default {
  name: "projects",
  components: {
    projectslist
  },
  methods: {
    newproject: function() {
      this.$router.push({ name: "newproject" });
    },
    contractors: function() {
      this.$router.push({ name: "contractors" });
    }
  }
};
