//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import newpackage from "./newpackage";
export default {
  data() {
    return {
      packagesList: [],
      columns: [
        {
          name: "packageImagePath",
          align: "left",
          label: "Imagen",
          field: "packageImagePath",
          sortable: true
        },
        {
          name: "name",
          align: "left",
          label: "Nombre",
          field: "name",
          sortable: true
        },
        {
          name: "products",
          align: "left",
          label: "Productos",
          field: "products",
          sortable: true
        },
        {
          name: "price",
          align: "left",
          label: "Precio",
          field: "price",
          sortable: true
        },
        { name: "actions", label: "Eliminar", field: "", align: "center" }
      ]
    };
  },
  components: {
    newpackage
  },
  beforeMount() {
    this.getpackageslist();
  },
  methods: {
    getpackageslist: function() {
      const self = this;
      this.isPackagesListEmpty = false;
      firebase
        .firestore()
        .collection("packages")
        .onSnapshot(querySnapshot => {
          let packages = [];
          querySnapshot.forEach(doc => {
            let pkg = doc.data();
            pkg.id = doc.id;
            packages.push(pkg);
          });
          self.packagesList = packages;
        });
    },
    deletpackage: function(packageitem) {
      const self = this;
      const packitem = packageitem;
      this.$q
        .dialog({
          title: "Eliminar " + packitem.name,
          message: "Desea eliminar este almacén? ",
          ok: {
            push: true,
            label: "Confirmar"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Cancelar"
          }
        })
        .onOk(() => {
          firebase
            .firestore()
            .collection("packages")
            .doc(packitem.id)
            .delete()
            .catch(function(error) {
              self.$buefy.toast.open("Error removing document: ", error);
            });
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    gotosaleslist: function() {
      this.$router.push({ name: "purchasesandsales" });
    }
  }
};
