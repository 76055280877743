//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      contractor: {}
    };
  },
  created() {
    this.contractor = this.$route.params.contractor;
  },
  methods: {
    gotoContractors: function() {
      this.$router.push({ name: "contractors" });
    }
  }
};
