//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  props: ["warehouse", "reducestockflagevent"],
  data() {
    return {
      productslist: [],
      isProductsListEmpty: true,
      productid: null,
      maxquantity: 1,
      productquantity: 0,
      reason: "",
    };
  },
  beforeMount() {
    this.getproductslist();
  },
  methods: {
    onSelectChange: function () {
      this.productslist.forEach((prod) => {
        if (prod.productid == this.productid) {
          this.maxquantity = prod.productquantity;
        }
      });
    },
    getproductslist: function () {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("stock")
        .where("warehouse", "==", self.warehouse.name)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let prod = doc.data();
            prod.id = doc.id;
            self.productslist.push(prod);
          });
        });
    },
    reducestock: function () {
      const self = this;
      var product;
      this.productslist.forEach((prod) => {
        if (prod.productid == this.productid) {
          product = prod;
        }
      });
      firebase
        .firestore()
        .collection("returnstock")
        .add({
          date: new Date(),
          product: product,
          warehouse: self.warehouse.name,
          quantity: self.productquantity,
          reason: self.reason,
          status: false,
        })
        .then(() => {
          self.$emit("reducestockflagevent", false);
        });
    },
  },
};
