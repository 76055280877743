//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import materialrequest from "./materials/request";
import materialreturn from "./materials/returns";
export default {
  data() {
    return {
      tab: "requests"
    };
  },
  components: {
    materialrequest,
    materialreturn
  }
};
