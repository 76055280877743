//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  name: "newuser",
  data() {
    return {
      name: "",
      ci: "",
      role: "",
      email: "",
      password: "",
      phone: "",
      address: "",
      profileimage: null,
      profileimagepath: null,
      confirmpassword: "",
      roleoptions: [
        "Gerente General",
        "Jefe de Compras y Ventas",
        "Agente de Ventas",
        "Project Manager",
        "Jefe de Nacional de Almacenes",
        "Jefe de Almacen",
        "Supervisor",
        "Responsable de División Outsourcing y RRHH",
        "Jefe de marketing",
        "Especialista de adquisiciones"
      ],
      errorMessage: ""
    };
  },
  methods: {
    registeruser: function() {
      const self = this;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          alert(errorCode + errorMessage);
          // ...
        })
        .then(data => {
          firebase
            .firestore()
            .collection("users")
            .doc(data.user.uid)
            .set({
              name: self.name,
              ci: self.ci,
              role: self.role,
              email: self.email,
              profileImagePath: null,
              phone: self.phone,
              address: self.address,
              status: true
            })
            .then(function() {
              if (self.profileimage != null) {
                self.uploadfile(self.profileimage, data.user.uid);
              }
              self.onReset();
            })
            .catch(function(error) {
              self.$buefy.toast.open("Error writing document: ", error);
            });
        });
    },
    uploadfile: function(file, productid) {
      //const self = this;
      const pid = productid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg"
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("users/" + pid + ".jpg")
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            firebase
              .firestore()
              .collection("users")
              .doc(pid)
              .update({
                profileImagePath: downloadURL
              });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    onReset() {
      this.name = null;
      this.ci = null;
      this.role = null;
      this.email = null;
      this.password = null;
      this.profileimage = null;
      this.profileImagePath = "";
      this.confirmpassword = null;
    }
  }
};
