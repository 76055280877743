//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
import materialrequest from "../../components/warehouse/materialrequest";
import materialreturn from "../../components/warehouse/materialreturn";
import reducestock from "./reducestock";
export default {
  name: "viewwarehouse",
  data() {
    return {
      stockinwarehouse: [],
      warehouse: {},
      productfilter: "",
      pendinfsalesfilter: "",
      pendingstockfilter: "",
      products: [],
      reducestockflag: false,
      pagination: {
        sortBy: "desc",
        descending: false,
        rowsPerPage: 25,
        rowsNumber: this.userlistlenght,
      },
      columns: [
        {
          name: "productImagePath",
          label: "Producto",
          field: "productImagePath",
          align: "left",
          sortable: false,
        },
        {
          name: "productmodel",
          label: "Producto",
          field: "productmodel",
          align: "left",
          sortable: true,
        },
        {
          name: "productquantity",
          label: "Cantidad",
          field: "productquantity",
          align: "center",
          sortable: true,
        },
      ],
      deliver: false,
      proofofsale: null,
      proofofreception: null,
      pendingdeliverylist: [],
      pendingdeliverycolumns: [
        {
          name: "salevoucher",
          align: "left",
          label: "Código",
          field: "salevoucher",
          sortable: true,
        },
        {
          name: "client",
          required: true,
          label: "Cliente",
          align: "left",
          field: (row) => row.client.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
      stockcolumns: [
        {
          name: "vouchercode",
          align: "left",
          label: "Código de Entrega",
          field: "vouchercode",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: "Fecha de Ingreso",
          field: "date",
          sortable: true,
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
      stocklist: [],
      addstock: false,
      clients: [],
    };
  },
  components: {
    materialrequest,
    materialreturn,
    reducestock,
  },
  created() {
    this.warehouse = this.$route.params.warehouse;
  },
  beforeMount() {
    this.getclientlist();
    this.getpendingdeliverylist();
    this.getproductsinwarehouse();
    this.getstockslist();
  },
  methods: {
    getproductsinwarehouse: function () {
      const self = this;
      firebase
        .firestore()
        .collection("products")
        .get()
        .then((snap) => {
          const productsCollection = [];
          snap.forEach((doc) => {
            let product = doc.data();
            product.id = doc.id;
            productsCollection.push(product);
          });
          self.products = productsCollection;
        })
        .then(() => {
          firebase
            .firestore()
            .collection("stock")
            .where("warehouse", "==", this.warehouse.name)
            .onSnapshot((querySnapshot) => {
              let stocklist = [];
              querySnapshot.forEach((doc) => {
                let stock = doc.data();
                stock.id = doc.id;
                self.products.forEach((x) => {
                  if (x.id == stock.productid) {
                    stock.productImagePath = x.productImagePath;
                  }
                });
                stocklist.push(stock);
              });
              self.stockinwarehouse = stocklist;
            });
        });
    },

    getpendingdeliverylist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sales")
        .where("status", "==", "Pendiente de Entrega")
        .where("warehouse.id", "==", self.warehouse.id)
        .onSnapshot((querySnapshot) => {
          let sales = [];
          querySnapshot.forEach((doc) => {
            let sale = doc.data();
            sale.id = doc.id;
            self.clients.forEach((x) => {
              if (x.id == sale.clientid) {
                sale.client = x;
              }
            });
            sales.push(sale);
          });
          self.pendingdeliverylist = sales;
        });
    },
    getclientlist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .onSnapshot((querySnapshot) => {
          let warehouseslist = [];
          querySnapshot.forEach((doc) => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouseslist.push(warehouse);
          });
          self.clients = warehouseslist;
        });
    },
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getstockslist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("stockvoucher")
        .where("warehouse", "==", self.warehouse.name)
        .where("status", "==", "En Camino")
        .onSnapshot((querySnapshot) => {
          let stocklist = [];
          querySnapshot.forEach((doc) => {
            let stock = doc.data();
            stock.id = doc.id;
            stocklist.push(stock);
          });
          self.stocklist = stocklist;
        });
    },
    addproofofsale: function (sale) {
      const self = this;
      let flag = true;
      sale.listofproductsinvoucher.forEach((product) => {
        firebase
          .firestore()
          .collection("stock")
          .where("warehouse", "==", self.warehouse.name)
          .where("productid", "==", product.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let prod = doc.data();
              prod.id = doc.id;
              if (prod.productquantity < product.quantity) {
                flag = false;
              }
            });
          })
          .then(() => {
            sale.listofpackagesinvoucher.forEach((pack) => {
              pack.products.forEach((produ) => {
                firebase
                  .firestore()
                  .collection("stock")
                  .where("warehouse", "==", self.warehouse.name)
                  .where("productid", "==", produ.id)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      let prod = doc.data();
                      prod.id = doc.id;
                      if (prod.productquantity < produ.quantity) {
                        flag = false;
                      }
                    });
                  });
              });
            });
            if (flag) {
              firebase.firestore().collection("sales").doc(sale.id).update({
                deliverydate: new Date(),
                status: "Entregado",
              });
              self.uploadfile(self.proofofsale, sale.id, "sales");
              self.reducestock(sale);
              self.deliver = false;
            } else {
              self.$q.notify({
                message: "ERROR.",
                caption: "La cantidad de productos no es suficiente.",
                color: "danger",
              });
            }
          });
      });
    },
    reducestock: function (salein) {
      const sale = salein;
      sale.listofproductsinvoucher.forEach((product) => {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", sale.warehouse.name)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              let data = doc.data();
              let newquantity =
                Number(data.productquantity) - Number(product.quantity);
              firebase
                .firestore()
                .collection("stock")
                .doc(doc.id)
                .update({
                  productquantity: Number(newquantity),
                });
            });
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then((doc) => {
                let data = doc.data();
                let newstock = Number(data.stock) - Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock,
                  });
              });
          });
      });
      sale.listofpackagesinvoucher.forEach((pack) => {
        const packquantity = pack.quantity;
        pack.products.forEach((product) => {
          firebase
            .firestore()
            .collection("stock")
            .where("productid", "==", product.id)
            .where("warehouse", "==", sale.warehouse.name)
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                let data = doc.data();
                let newquantity =
                  Number(data.productquantity) -
                  Number(product.quantity) * Number(packquantity);
                firebase
                  .firestore()
                  .collection("stock")
                  .doc(doc.id)
                  .update({
                    productquantity: Number(newquantity),
                  });
              });
              firebase
                .firestore()
                .collection("products")
                .doc(product.id)
                .get()
                .then((doc) => {
                  let data = doc.data();
                  let newstock =
                    Number(data.stock) -
                    Number(product.quantity) * Number(packquantity);
                  firebase
                    .firestore()
                    .collection("products")
                    .doc(product.id)
                    .update({
                      stock: newstock,
                    });
                });
            });
        });
      });
    },
    addproofofreception: function (stock) {
      //const self=this;
      firebase.firestore().collection("stockvoucher").doc(stock.id).update({
        receptiondate: new Date(),
        status: "En Almacén",
      });
      this.uploadfile(this.proofofreception, stock.id, "stockvoucher");
      this.addingstock(
        stock.listofproductsinvoucher,
        stock.id,
        stock.warehouse
      );
      this.addstock = false;
    },
    openproofofreception: function (stock) {
      this.$router.push({
        name: "/warehouse/stock/view",
        params: { stock: stock, warehouse: this.warehouse },
      });
    },
    addingstock: function (products, stockid, warehouse) {
      products.forEach((product) => {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", warehouse)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              firebase
                .firestore()
                .collection("stock")
                .add({
                  productid: product.id,
                  productmodel: product.model,
                  productquantity: Number(product.quantity),
                  warehouse: warehouse,
                });
            } else {
              querySnapshot.forEach(function (doc) {
                let data = doc.data();
                let newquantity =
                  Number(product.quantity) + Number(data.productquantity);
                firebase
                  .firestore()
                  .collection("stock")
                  .doc(doc.id)
                  .update({
                    productquantity: Number(newquantity),
                  });
              });
            }
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then((doc) => {
                let data = doc.data();
                let newstock = Number(data.stock) + Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock,
                  });
              });
          });
      });
    },
    uploadfile: function (file, docid, ext) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef.child(ext + "/" + id + ".pdf").put(file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection(ext).doc(id).update({
              proof: downloadURL,
            });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    gotowarehouses: function () {
      this.$router.push({ name: "warehouses" });
    },
    gotoproofofdelivery: function (sale) {
      this.$router.push({
        name: "proofofdelivery",
        params: { sale: sale },
      });
    },
    openreducestock: function () {
      this.reducestockflag = true;
    },
    reducestockflagevent: function (reply)  {
      console.log(reply);
      this.reducestockflag = reply;
    }
  },
};
