//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      filter: '',
      data: [],
      columns: [
        {
          name: "code",
          label: "Código",
          field: "code",
          sortable: true,
          align: "left"
        },
        {
          name: "projectname",
          label: "Proyecto",
          field: "projectname",
          sortable: true,
          align: "left"
        },
        { name: "sitename", label: "Sitio", field: "sitename", align: "left" }
      ]
    };
  },
  beforeMount() {
    this.getapprovedmaterialrequests();
  },
  methods: {
    getapprovedmaterialrequests: function() {
      const self = this;
      firebase
        .firestore()
        .collection("materialrequest")
        .where("status", "==", "Aprobado")
        .onSnapshot(querySnapshot => {
          let requests = [];
          querySnapshot.forEach(doc => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    onRowClick(evt, row) {
      this.$router.push({
        name: "/purchasesandsales/materials/request/view",
        params: { materialrequest: row }
      });
    }
  }
};
