//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      packagesList: [],
      isPackagesListEmpty: true,
      name: "",
      productsList: [],
      listofproductsinpackage: [],
      lofivcolumns: [
        {
          name: "model",
          align: "left",
          label: "Producto",
          field: "model",
          sortable: true,
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true,
        },
      ],
      packageimage: null,
      packageImagePath: "",
      price: 0,
      productquantity: null,
      productid: null,
    };
  },
  beforeMount() {
    this.getproductslist();
  },
  methods: {
    getproductslist: function () {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("products")
        .onSnapshot((querySnapshot) => {
          let products = [];
          querySnapshot.forEach((doc) => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          self.productsList = products;
        });
    },
    getpackageslist: function () {
      const self = this;
      this.isPackagesListEmpty = false;
      firebase
        .firestore()
        .collection("packages")
        .onSnapshot((querySnapshot) => {
          let packages = [];
          querySnapshot.forEach((doc) => {
            let pkg = doc.data();
            pkg.id = doc.id;
            packages.push(pkg);
          });
          self.packagesList = packages;
        });
    },
    addproducttopackage: function () {
      this.islistofproductsinpackageEmpty = false;
      const self = this;
      this.productsList.forEach(function (item) {
        if (self.productid == item.id) {
          item.quantity=self.productquantity
          self.listofproductsinpackage.push(item);
          self.productid = null;
          self.productquantity = null;
        }
      });
    },
    registerpackage: function () {
      const self = this;
      firebase
        .firestore()
        .collection("packages")
        .add({
          name: self.name,
          products: self.listofproductsinpackage,
          price: self.price,
        })
        .then(function (doc) {
          self.uploadfile(self.packageimage, doc.id);
          self.name = "";
          self.listofproductsinpackage = [];
          self.islistofproductsinpackageEmpty = true;
          self.packageimage = null;
          self.packageImagePath = "";
          self.productquantity = null;
          self.productid = null;
          self.price = 0;
        })
        .catch(function (error) {
          self.$buefy.toast.open("Error writing document: ", error);
        });
    },
    uploadfile: function (file, packageid) {
      //const self = this;
      const pid = packageid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("packages/" + pid + ".jpg")
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection("packages").doc(pid).update({
              packageImagePath: downloadURL,
            });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
  },
};
