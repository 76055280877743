//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      client: {},
      filter: "",
      toupdate: false,
      submitting: false,
      clientslist: [],
      columns: [
        {
          name: "name",
          align: "left",
          label: "Nombre",
          field: "name",
          sortable: true
        },
        {
          name: "email",
          align: "left",
          label: "Correo Electronico",
          field: "email",
          sortable: true
        },
        {
          name: "phone",
          align: "left",
          label: "Teléfono",
          field: "phone",
          sortable: true
        },
        {
          name: "company",
          align: "left",
          label: "Empresa",
          field: "company",
          sortable: true
        },
        {
          name: "nit",
          align: "left",
          label: "N.I.T",
          field: "nit",
          sortable: true
        },
        {
          name: "city",
          align: "left",
          label: "Ciudad",
          field: "city",
          sortable: true
        },
        { name: "actions", label: "Acciones", field: "", align: "center" }
      ]
    };
  },
  beforeMount() {
    this.getclientslist();
  },
  methods: {
    //registerwarehouse: function(params) {},
    getclientslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .onSnapshot(querySnapshot => {
          let clientslist = [];
          querySnapshot.forEach(doc => {
            let client = doc.data();
            client.id = doc.id;
            clientslist.push(client);
          });
          self.clientslist = clientslist;
        });
    },
    registerclient: function() {
      const self = this;
      this.submitting = true;
      firebase
        .firestore()
        .collection("clients")
        .add(self.client);
      self.onReset();
    },
    editclient: function(client) {
      this.toupdate = true;
      this.client = client;
    },
    updateclient: function() {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .doc(this.client.id)
        .update({
          name: self.client.name,
          email: self.client.email,
          phone: self.client.phone,
          company: self.client.company,
          nit: self.client.nit,
          city: self.client.city
        });
      this.onReset();
    },
    onReset() {
      this.client = {};
      this.toupdate = false;
      this.submitting=false;
    },
    gotosaleslist: function() {
      this.$router.push({ name: "saleslist" });
    }
  }
};
