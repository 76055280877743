//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      requestmessage: "",
      client: null,
      agent: null,
      data: [],
      filter: "",
      toupdate: false,
      submitting: "",
      clientslist: [],
      agentslist: [],
      columns: [
        {
          name: "salevoucher",
          align: "left",
          label: "Código de Venta",
          field: "salevoucher",
          sortable: true,
        },
        {
          name: "clientname",
          align: "left",
          label: "Cliente",
          field: "clientname",
          sortable: true,
        },
        {
          name: "agentname",
          align: "left",
          label: "Agente",
          field: "agentname",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: "Fecha",
          field: "date",
          sortable: true
        },
        { name: "actions", label: "Acciones", field: "", align: "center" },
      ],
    };
  },
  computed: {
    ...mapState(["profile", "islogged"]),
  },
  beforeMount() {
    this.getclientslist();
    this.getagentslist();
    this.getsaleslist();
  },
  methods: {
    converttimestamp: function(date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getsaleslist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sales")
        .where("status", "==", "Pendiente de Aprobación")
        .onSnapshot((querySnapshot) => {
          let sales = [];
          querySnapshot.forEach((doc) => {
            let sale = doc.data();
            sale.id = doc.id;
            self.clientslist.forEach((x) => {
              if (x.id == sale.clientid) {
                sale.client = x;
              }
            });
            self.agentslist.forEach((x) => {
              if (x.id == sale.agentid) {
                sale.agent = x;
              }
            });
            sales.push(sale);
          });
          self.data = sales;
        });
    },
    getclientslist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("clients")
        .onSnapshot((querySnapshot) => {
          let clients = [];
          querySnapshot.forEach((doc) => {
            let client = doc.data();
            client.id = doc.id;
            clients.push(client);
          });
          self.clientslist = clients;
        });
    },
    getagentslist: function () {
      const self = this;
      this.isagentsListEmpty = false;
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", "Agente de Ventas")
        .onSnapshot((querySnapshot) => {
          let agents = [];
          querySnapshot.forEach((doc) => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.agentslist = agents;
        });
    },
    approvesale: function (sale) {
      const self = this;
      const packages = sale.listofpackagesinvoucher;
      const products = sale.listofproductsinvoucher;
      var flag = false;
      firebase
        .firestore()
        .collection("stock")
        .where("warehouse", "==", sale.warehouse.name)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            var stock = doc.data();
            products.forEach((product) => {
              if (product.quantity > stock.productquantity) {
                flag = true;
              }
            });
            packages.forEach((p) => {
              p.products.forEach((product) => {
                if (product.quantity > stock.productquantity) {
                  flag = true;
                }
              });
            });
          });
          if (flag) {
            self.$q.notify({
              message: "No hay stock suficiente",
              color: "red",
            });
          } else {
            firebase.firestore().collection("sales").doc(sale.id).update({
              status: "Aprobado",
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      /**/
    },
    rejectsale: function (saleid) {
      const requestid = saleid;
      this.$q
        .dialog({
          title: "Confirmar",
          message: "Cual es el motivo de rechazar esta solicitud?",
          prompt: {
            model: "",
            isValid: (val) => val.length > 2, // << here is the magic
            type: "text", // optional
          },
          cancel: true,
          persistent: true,
        })
        .onOk((data) => {
          firebase.firestore().collection("sales").doc(requestid).update({
            status: "Rechazado",
            rejectmessage: data,
          });
        });
    },
    gotoproductlist: function () {
      this.$router.push({ name: "productslist" });
    },
    viewsale: function (sale) {
      this.$router.push({ name: "viewsale", params: { sale: sale } });
    },
  },
};
