import Vue from 'vue'

import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import {
  Quasar,
  Dialog,
  Notify,
  QDialog,
  ClosePopup
} from 'quasar'

Vue.use(Quasar, {
  config: {
    notify: { /* Notify defaults */ }
  },
  components: { QDialog },
  directives: { ClosePopup },
  plugins: {
    Dialog,
    Notify
  }
})