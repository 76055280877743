//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      request: {},
      projectslist: [],
      warehouseslist:[],
      productsList: [],
      productid: null,
      warehouseid:null,
      productquantity: 0,
      listofproducts: [],
      siteslist: null,
      submitting: false,
      columns: [
        {
          name: "model",
          align: "left",
          label: "Nombre",
          field: "model",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        }
      ]
    };
  },
  beforeMount() {
    this.getprojectslist();
    this.getproductslist();
    this.getwarehouseslist();
  },
  methods: {
    getprojectslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("projects")
        .where("status", "==", "En Proceso")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.projectslist = agents;
        });
    },
    getwarehouseslist: function() {
      const self = this;
      this.isWarehousesEmpty = false;
      firebase
        .firestore()
        .collection("warehouses")
        .where("enable", "==", true)
        .onSnapshot(querySnapshot => {
          let warehouses = [];
          querySnapshot.forEach(doc => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouses.push(warehouse);
          });
          self.warehouseslist = warehouses;
        });
    },
    getproductslist: function() {
      const self = this;
      this.isProductsListEmpty = false;
      firebase
        .firestore()
        .collection("products")
        .onSnapshot(querySnapshot => {
          let products = [];
          querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          self.productsList = products;
        });
    },
    addproduct: function() {
      if (
        this.productid != null &&
        this.productquantity != null &&
        this.productquantity > 0
      ) {
        var prodname;
        const self = this;
        this.productsList.forEach(function(item) {
          if (self.productid == item.id) {
            prodname = item.model;
          }
        });
        this.listofproducts.push({
          id: this.productid,
          model: prodname,
          quantity: Number(this.productquantity),
          used:0
        });
        this.productid = null;
        this.productquantity = null;
      } else {
        this.$q.notify({
          message: "Ingrese los datos correctos",
          color: "negative"
        });
      }
    },
    onProjectChange: function() {
      const self = this;
      firebase
        .firestore()
        .collection("projectsites")
        .where("projectid", "==", self.request.projectid)
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.siteslist = agents;
        });
    },
    register: function() {
      const self = this;
      this.submitting = true;
      self.projectslist.forEach(x => {
        if (x.id == self.request.projectid) {
          self.request.projectname = x.name;
          self.request.projectmanagerid= x.projectmanagerid;
        }
      });
      self.siteslist.forEach(x => {
        if (x.id == self.request.siteid) {
          self.request.sitename = x.name;
        }
      });
      self.warehouseslist.forEach(x=>{
        if(x.id==self.warehouseid){
          let warehouse={};
          warehouse.id=x.id;
          warehouse.name=x.name
          self.request.warehouse=warehouse;
        }
      })
      firebase
        .firestore()
        .collection("materialrequest")
        .add({
          code: Math.floor(Math.random() * 100000) + 1,
          projectid: self.request.projectid,
          projectname: self.request.projectname,
          projectmanagerid: self.request.projectmanagerid,
          sitename: self.request.sitename,
          siteid: self.request.siteid,
          listofproducts: self.listofproducts,
          warehouse: self.request.warehouse,
          status: "En Proceso"
        })
        .then(() => {
          self.onReset();
        });
    },
    onReset: function() {
      this.request = {};
      this.listofproducts = [];
      this.warehouseid='';
      this.siteslist = null;
      this.submitting = false;
    }
  }
};
