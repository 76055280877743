//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  data() {
    return {
      returns: {},
      projectslist: [],
      siteslist: null,
      projectselected: false,
      siteselected: false,
      submitting: false,
      columns: [
        {
          name: "model",
          align: "left",
          label: "Nombre",
          field: "model",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        }
      ],
      materialcolumns: [
        {
          name: "model",
          align: "left",
          label: "Material",
          field: "model",
          sortable: true
        },
        {
          name: "quantity",
          align: "left",
          label: "Cantidad",
          field: "quantity",
          sortable: true
        }
      ]
    };
  },
  beforeMount() {
    this.getprojectslist();
  },
  methods: {
    getprojectslist: function() {
      const self = this;
      firebase
        .firestore()
        .collection("projects")
        .where("status", "==", "En Proceso")
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.projectslist = agents;
        });
    },
    onProjectSelected: function() {
      const self = this;
      firebase
        .firestore()
        .collection("projectsites")
        .where("projectid", "==", self.returns.projectid)
        .onSnapshot(querySnapshot => {
          let agents = [];
          querySnapshot.forEach(doc => {
            let agent = doc.data();
            agent.id = doc.id;
            agents.push(agent);
          });
          self.siteslist = agents;
          if (self.siteslist != null) {
            self.projectselected = true;
          }
        });
    },
    onSiteSelected: function() {
      const self = this;
      this.siteslist.forEach(site => {
        if (self.returns.siteid == site.id) {
          firebase
            .firestore()
            .collection("sitematerials")
            .where("siteid", "==", site.id)
            .onSnapshot(querySnapshot => {
              let materials = [];
              querySnapshot.forEach(doc => {
                let material = doc.data();
                material.id = doc.id;
                let mat = {};
                mat.id = material.productid;
                mat.model = material.productmodel;
                mat.quantity = material.productquantity - material.used;
                materials.push(mat);
              });
              self.returns.materials = materials;
              self.siteselected = true;
            });
        }
      });
    },
    register: function() {
      const self = this;
      this.submitting = true;
      let site = {};
      let project;
      self.siteslist.forEach(s => {
        if (s.id == self.returns.siteid) {
          site = s;
        }
      });
      self.projectslist.forEach(p => {
        if (p.id == self.returns.projectid) {
          project = p;
        }
      });
      firebase
        .firestore()
        .collection("sitematerialreturn")
        .where("site.id", "==", site.id)
        .onSnapshot(querySnapshot => {
          if (querySnapshot.empty) {
            firebase
              .firestore()
              .collection("sitematerialreturn")
              .add({
                code: Math.floor(Math.random() * 100000) + 1,
                site: site,
                project: project,
                status: 1,
                materials:self.returns.materials,
                created: firebase.firestore.FieldValue.serverTimestamp()
              })
              .then(() => {
                self.onReset();
              });
          } else {
            self.$q.notify({
              message: "Ya se registro el sitio",
              color: "warning"
            });
            self.onReset();
          }
        });
    },
    onReset: function() {
      this.returns = {};
      this.siteslist = null;
      this.submitting = false;
      this.projectselected = false;
      this.siteselected = false;
    }
  }
};
