//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
import moment from "moment";
export default {
  data() {
    return {
      work: {},
      site: {},
      materialdelivery: false,
      deliverynote: false,
      instalation_latitude: 0,
      instalation_longitude: 0,
      Installationmap: false,
      contractor: {},
      mapcenter: {},
      returnedmaterial: {},
      workinstallations: [],
      columns: [
        {
          name: "model",
          align: "left",
          label: "Material",
          field: "model",
          sortable: true,
        },
        {
          name: "quantity",
          align: "center",
          label: "Cantidad",
          field: "quantity",
          sortable: true,
        },
        {
          name: "used",
          align: "center",
          label: "Instalado",
          field: "used",
          sortable: true,
        },
      ],
      columnsofinstallations: [
        {
          name: "model",
          align: "left",
          label: "Material",
          field: "model",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "quantity",
          align: "center",
          label: "Cantidad",
          field: "quantity",
          headerClasses: "bg-primary text-white",
        },
      ],
    };
  },
  beforeMount() {
    this.getcontractor();
    this.getsite();
    this.getwork();
    this.getworkinstallations();
    this.getreturnedmaterial();
  },
  created() {
    this.work = this.$route.params.work;
    this.site = this.$route.params.site;
  },
  methods: {
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    getreturnedmaterial: function () {
      const self = this;
      firebase
        .firestore()
        .collection("returnworkremainder")
        .where("workid", "==", self.work.id)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let data = doc.data();
            data.id = doc.id;
            self.returnedmaterial = data;
          });
        });
    },
    getcontractor: function () {
      const self = this;
      firebase
        .firestore()
        .collection("contractors")
        .doc(self.work.contractorid)
        .get()
        .then(function (doc) {
          self.contractor = doc.data();
          self.contractor.id = doc.id;
        });
    },
    getsite: function () {
      const self = this;
      firebase
        .firestore()
        .collection("projectsites")
        .doc(self.work.siteid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.site = doc.data();
            self.site.id = doc.id;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    getwork: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sitework")
        .doc(self.work.id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            self.work = doc.data();
            self.work.id = doc.id;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    },
    getworkinstallations: function () {
      const self = this;
      firebase
        .firestore()
        .collection("workinstallation")
        .where("workid", "==", self.work.id)
        .onSnapshot((querySnapshot) => {
          let requests = [];
          querySnapshot.forEach((doc) => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.workinstallations = requests;
        });
    },
    openInstallationMap: function (data) {
      console.log(data);
      this.instalation_latitude = data.location.latitude;
      this.instalation_longitude = data.location.longitude;
      this.Installationmap = true;
    },
    gotoSite: function () {
      this.$router.push({
        name: "viewprojectsite",
        params: { site: this.site },
      });
    },
    gotoAddInstallation: function () {
      this.$router.push({
        name: "addworkinstallation",
        params: { work: this.work },
      });
    },
    gotoFinishWork: function () {
      this.$router.push({
        name: "finishsitework",
        params: { work: this.work },
      });
    },
  },
};
