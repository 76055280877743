//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase";
export default {
  props: ["warehouse"],
  data() {
    return {
      filter: "",
      data: [],
      addproof: false,
      proofofreception: null,
      columns: [
        {
          name: "code",
          label: "Código",
          field: "code",
          sortable: true,
          align: "left",
        },
        {
          name: "projectname",
          label: "Proyecto",
          field: "projectname",
          sortable: true,
          align: "left",
        },
        { name: "sitename", label: "Sitio", field: "sitename", align: "left" },
        {
          name: "actions",
          align: "center",
          label: "Acciones",
          field: "actions",
          sortable: true,
        },
      ],
    };
  },
  beforeMount() {
    this.getapprovedmaterialrequests();
  },
  methods: {
    getapprovedmaterialrequests: function () {
      const self = this;
      firebase
        .firestore()
        .collection("materialrequest")
        .where("status", "==", "Aprobado")
        .where("warehouse.id", "==", self.warehouse.id)
        .onSnapshot((querySnapshot) => {
          let requests = [];
          querySnapshot.forEach((doc) => {
            let request = doc.data();
            request.id = doc.id;
            requests.push(request);
          });
          self.data = requests;
        });
    },
    approveRequest: function (row) {
      const self = this;
      let flag = true; //Flag que ayuda a saber si existe la cantidad de productos en almacen
      row.listofproducts.forEach((product) => {
        firebase
          .firestore()
          .collection("stock")
          .where("warehouse", "==", self.warehouse.name)
          .where("productid", "==", product.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let prod = doc.data();
              prod.id = doc.id;
              if (prod.productquantity < product.quantity) {
                flag = false;
              }
            });
          })
          .then(() => {
            if (flag) {
              firebase
                .firestore()
                .collection("materialrequest")
                .doc(row.id)
                .update({
                  status: "Entregado",
                });
              this.addmaterialstosite(product, row.siteid, row.projectid);
              this.reducestock(product, row.warehouse);
              this.uploadfile(
                this.proofofreception,
                row.id,
                "materialrequest"
              ).then(() => {
                self.onReset();
              });
            } else {
              self.$q.notify({
                message: "ERROR.",
                caption: "La cantidad de productos no es suficiente.",
                color: "danger",
              });
            }
          });
      });
    },
    addmaterialstosite: function (product, siteid, projectid) {
      firebase
        .firestore()
        .collection("sitematerials")
        .where("productid", "==", product.id)
        .where("siteid", "==", siteid)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.empty) {
            firebase
              .firestore()
              .collection("sitematerials")
              .add({
                productid: product.id,
                productmodel: product.model,
                productquantity: Number(product.quantity),
                siteid: siteid,
                projectid: projectid,
                used: 0,
              });
          } else {
            querySnapshot.forEach(function (doc) {
              let data = doc.data();
              let newquantity =
                Number(product.quantity) + Number(data.productquantity);
              firebase
                .firestore()
                .collection("sitematerials")
                .doc(doc.id)
                .update({
                  productquantity: Number(newquantity),
                });
            });
          }
        });
    },
    reducestock: function (product, warehouse) {
        firebase
          .firestore()
          .collection("stock")
          .where("productid", "==", product.id)
          .where("warehouse", "==", warehouse.name)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              let data = doc.data();
              let newquantity =
                Number(data.productquantity) - Number(product.quantity);
              firebase
                .firestore()
                .collection("stock")
                .doc(doc.id)
                .update({
                  productquantity: Number(newquantity),
                });
            });
            firebase
              .firestore()
              .collection("products")
              .doc(product.id)
              .get()
              .then((doc) => {
                let data = doc.data();
                let newstock = Number(data.stock) - Number(product.quantity);
                firebase
                  .firestore()
                  .collection("products")
                  .doc(product.id)
                  .update({
                    stock: newstock,
                  });
              });
          });
    },
    uploadfile: function (file, docid, ext) {
      const id = docid;
      // Firebase Storage
      var storageRef = firebase.storage().ref();

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef.child(ext + "/" + id + ".pdf").put(file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebase.firestore().collection(ext).doc(id).update({
              proof: downloadURL,
            });
            //console.log("File available at", downloadURL);
          });
        }
      );
    },
    viewRequest: function (row) {
      this.$router.push({
        name: "/warehouse/materialrequest/view",
        params: { materialrequest: row },
      });
    },
    onReset: function () {
      this.addproof = false;
      this.proofofreception = null;
    },
  },
};
