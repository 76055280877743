import { render, staticRenderFns } from "./userslist.vue?vue&type=template&id=9a9e5724&"
import script from "./userslist.vue?vue&type=script&lang=js&"
export * from "./userslist.vue?vue&type=script&lang=js&"
import style0 from "./userslist.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QAvatar,QTable,QInput,QIcon,QInnerLoading,QTr,QTd,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QAvatar,QTable,QInput,QIcon,QInnerLoading,QTr,QTd,QBtn})
