import { render, staticRenderFns } from "./newsite.vue?vue&type=template&id=ed8d6306&"
import script from "./newsite.vue?vue&type=script&lang=js&"
export * from "./newsite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QCard,QCardSection,QForm,QInput,QSelect} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QCard,QCardSection,QForm,QInput,QSelect})
